import React, { Component, useEffect, useState, Text } from 'react'
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import Header_Recent_Matches from './Header_Recent_Matches';
import Header_Live_Matches from './Header_Live_Matches';
import Header_All_Matches from './Header_All_Matches';
import Carousels from './Carousels';
import Header_Schedule_Matches from './Header_Schedule_Matches';
 

export default function Header_Tab() {
  return (
    <> <Tabs
    activeTab="2"
    className=""
    ulClassName=""
    activityClassName="bg-success"
    onClick={(event, tab) => console.log(event, tab)}
  >
    
      <Tab title="Live" className="mr-3">
          <div className="mt-3 ">
       <Header_Live_Matches></Header_Live_Matches>
          </div>
      </Tab>
      <Tab title="Recent" className="mr-3">
          <div className="mt-3">
          <Header_Recent_Matches></Header_Recent_Matches>
          </div>
      </Tab>
      <Tab title="Schedule" className="mr-3">
          <div className="mt-3">
           
     <Header_Schedule_Matches></Header_Schedule_Matches>
           {/* <Header_All_Matches></Header_All_Matches> */}
          </div>
      </Tab>
  </Tabs></>  
  )
}
