import React from 'react'
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import SideBar from '../../Components/SideBar';
 import BatsMenODI from './BatsMenODI';
 import BatsMenTests from './BatsMenTests';
 import BatsMenT20 from './BatsMenT20';
 
 
export default function BatsMenTabs() {
  return (
    <div> 

  

<div className='side-content mt-3'>
    <div className='container'>
      <div className='row'>
      <div className='col-sm-8'>
      <Tabs
    activeTab="1"
    className=""
    ulClassName="ranking"
    activityClassName="bg-success"
    onClick={(event, tab) => console.log(event, tab)}
  >
    


    
      <Tab title="Odi" className="mr-3 ">
          <div className="mt-3 ">
 <BatsMenODI></BatsMenODI>
          </div>
      </Tab>
      <Tab title="T20" className="mr-3">
          <div className="mt-3">
          <BatsMenT20></BatsMenT20>
          </div>
      </Tab>
      <Tab title="Test" className="mr-3">
          <div className="mt-3">
          <BatsMenTests></BatsMenTests>
          </div>
      </Tab>
  </Tabs>
     
    </div>
    <div className='col-sm-4'>
 <SideBar></SideBar>
    </div>
    </div>
    </div>
    </div>



       
    </div>
  )
}
