 import React, { useEffect, useState, Text } from "react";
 import {Link} from "react-router-dom";

export default function BreakingNews() {


    const [news,setData] = useState([])
    useEffect(() => {
      fetch(`https://admin.cricplex.com/mobileapi/top10news`).then((result)=>{
        result.json().then((resp)=>{
           // console.log(resp.news)
          setData(resp.news.slice(0, 6));
        
        })
      })
  
    },[])


  return (
    <div>	<div className="marquee " data-startvisible="true" data-duration="18000" data-gap="10" data-duplicated="true">
    <marquee className="posts  posts--inline">
    {
                news.map((value,index) => {
                return (
        <li className="posts__item">
            <h5 className="posts_title breaking_news_text text-red"><Link to={'news-detail/'+value.id}>
             <span class="dot"></span>          {value.heading}    
</Link></h5>
        </li>
        );
    })
    } 
        
            
                     
                     
                     {/* <li className="posts__item">
            <h6 className="posts__title text-white"><a href="#"> Advertise Your Brand in Cricplex Radio, </a></h6>
            <div className="posts__excerpt text-red">Book Your Ads Today !! </div>
        </li> */}
    </marquee>
</div></div>
  )
}
