import React from 'react'
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import SideBar from '../../Components/SideBar';
import ODI from './ODI';
import T20 from './T20';
import Tests from './Tests';
export default function TeamTabs() {
  return (
    <div>



<div className='side-content mt-3'>
    <div className='container'>
      <div className='row'>
      <div className='col-sm-8'>
      <Tabs
    activeTab="1"
    className=""
    ulClassName="ranking"
    activityClassName="bg-success"
    onClick={(event, tab) => console.log(event, tab)}
  >
    


    
      <Tab title="Odi" className="mr-3 ">
          <div className="mt-3 ">
 <ODI></ODI>
          </div>
      </Tab>
      <Tab title="T20" className="mr-3">
          <div className="mt-3">
   <T20></T20>
          </div>
      </Tab>
      <Tab title="Test" className="mr-3">
          <div className="mt-3">
           
 <Tests></Tests>
          </div>
      </Tab>
  </Tabs>
     
    </div>
    <div className='col-sm-4'>
 <SideBar></SideBar>
    </div>
    </div>
    </div>
    </div>



       
    </div>
  )
}
