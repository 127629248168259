import React, { useEffect, useState, Text } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import HomeTabsNews from "./HomeTabsNews";
import SideBar from "./SideBar";

export default function News() {
  const [news, setNews] = useState([])
      const getAnswer = async () => {
       await axios.get("https://admin.cricplex.com/example/allnews")
        .then((response) => {
       // console.log(response);
          setNews(response.data.data)
          
        })
    };
    useEffect(() => {
               getAnswer();
            });

            const navigate = useNavigate();
            const handleClick = (data) => {
              axios.get("https://admin.cricplex.com/example/avrnewsdetail/"+data.id)
                .then((response) => {
                      navigate('/news-detail',{
                          state: response.data.data
               });
                   
                  })
             
            }
            
  
    return (
    <>
         <div class="page-heading">   
   <div class="container">
      <div class="row">
         <div class="col-md-10 offset-md-1">
            <h1 class="page-heading__title">Latest <span class="highlight"> Sports News</span></h1>
            <ol class="page-heading__breadcrumb breadcrumb">
               <li class="breadcrumb-item"><a href="/">Home</a></li>
               <li class="breadcrumb-item active" aria-current="page">Latest Updates</li>
            </ol>
         </div>
      </div>
   </div>
</div>
       <div class="site-content">
				<div class="container">
				<div class="row">
				<div class="content col-md-8">
                   
				<div class="card card--clean">
				<div class="posts posts--cards post-grid row">
             
                {
                news.map((value,index) => {
                return (

				<div class="post-grid__item col-sm-6">
	            <div   class="posts__item posts__item--card posts__item--category-1 card" title="title">
				<figure class="posts__thumb">
				<div class="posts__cat">
				 <span class="label posts__cat-label">{value.category}</span>
				</div>
                <a href={"/news-detail/"+ value.id} >
                    <img src={"https://admin.cricplex.com/news_images/"+ value.image} alt="" />
                </a>
				 </figure>
				 <div class="posts__inner card__content">
                 <a href={"/news-detail/"+ value.id} class="posts__cta"></a>
				 <time datetime="2016-08-23" class="posts__date">{value.createdate}</time>
				<h3 class="posts__title"><a href="#">{value.heading} </a></h3>
				<div class="posts__excerpt" > 	{value.summery} ...
                <a href={"/news-detail/"+ value.id}>Read More</a>											
					</div>
					</div>
					</div>
					</div> 

                    );
                    })
                    } 

					</div>
					 
					</div>
					</div> 
                    <div id="sidebar" class="sidebar col-md-4">
			 
					
					 
					  
                        <SideBar></SideBar>  
						</div>

					</div> 
					</div> 
					</div>
       
    </>
  )
}

