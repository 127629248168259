import React from 'react'
import SideBar from './SideBar'

export default function HomeRadio() {
  return (
    <>
      
	  <aside className="widget card widget--sidebar widget-standings">
      <div className="widget__title card__header card__header--has-btn live_radio d-flex">
        <h4><span className='text-danger'>Live </span>Stream</h4>
        <img src={"/assets/images/liner_bg.png"} className='radio_flag' /> 
        <img className=' ml-auto mic_radio' src={"/assets/images/mic.png"}    /> 
       </div>
		
	   <div className="widget__content card__content">
						<article class="">
                        <iframe     width="100%" height="350" src="https://player.onestream.live/embed?token=NTY1ODM=&type=up" allow="autoplay" scrolling="no" frameborder="0"   allowfullscreen></iframe>
                       
					   {/* <iframe src="https://player.onestream.live/embed?token=MTU0NDU5Nzk=&type=event"  width="100%" height="370"  frameborder="0" allow="autoplay" allowfullscreen scrolling="no" > </iframe> */}

             
					   
					     
						</article>
						</div>
    </aside>  
                        

					 
    </>
  )
}
