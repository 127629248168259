import React from 'react'
import Para_Player_tab from './Para_Player_tab'
import IplNews from '../IplNews'
import Paralympic_News from '../Paralympic_News'

export default function PlayerProfile() {
  return (
    <>

<div class="player-heading">
      <div class="container">
        <div class="player-info__team-logo">
          <img src={"https://png.pngtree.com/png-vector/20240108/ourmid/pngtree-india-round-flag-glossy-glass-effect-vector-transparent-background-png-image_11427453.png"} width="100%" alt="" />
        </div>
        <div class="player-info__title player-info__title--mobile">
          <div class="player-info__number">T35</div>
          <h1 class="player-info__name">
            <span class="player-info__first-name">Narayan</span>
            <span class="player-info__last-name">Thankur</span>
          </h1>
        </div>
        <div class="player-info">
       
          <div class="player-info__item player-info__item--photo">
            <figure class="player-info__photo">
              <img src={"../assets/images/narayan_thakur.png"} width="100%" alt="" />
            </figure>
          </div>
         
          <div class="player-info__item player-info__item--details">
            <div class="player-info__title player-info__title--desktop">
              <div class="player-info__number">T35</div>
              <h1 class="player-info__name">
                <span class="player-info__first-name">Narayan</span>
                <span class="player-info__last-name">Thankur</span>
              </h1>
            </div>
            <div class="player-info-details">
              <div class="player-info-details__item player-info-details__item--height">
                <h6 class="player-info-details__title">Height</h6>
                <div class="player-info-details__value">6'9"</div>
              </div>
              {/* <div class="player-info-details__item player-info-details__item--weight">
                <h6 class="player-info-details__title">Weight</h6>
                <div class="player-info-details__value">295 lbs</div>
              </div> */}
              <div class="player-info-details__item player-info-details__item--age">
                <h6 class="player-info-details__title">Age</h6>
                <div class="player-info-details__value">33</div>
              </div>
              <div class="player-info-details__item player-info-details__item--born">
                <h6 class="player-info-details__title">Hometown</h6>
                <div class="player-info-details__value">
                New Delhi

                </div>
              </div>
              </div>
              <div class="player-info-details__item player-info-details__item--college">
                <h6 class="player-info-details__title">Current Training Base</h6>
                <div class="player-info-details__value">
                Tyagraj Stadium and IIT, Delhi.
                </div>
              </div>
          
              <div class="player-info-details__item player-info-details__item--position">
                <h6 class="player-info-details__title">Sport</h6>
                <div class="player-info-details__value">
                Athletics - 100m, 200m (T35)

                </div>
              
            </div>
          
          </div>
        
          <div class="player-info__item player-info__item--stats">
          <img src={"../assets/images/gold_medal.png"} width="100%" alt="" />
          </div>
       
        </div>
      </div>
    </div>

    <Para_Player_tab></Para_Player_tab>

    <div class="site-content">
			<div class="container">
				<div class="row"> 
					<div class="content col-lg-8"> 
						<article class="card card--lg post post--single">
							<figure class="post__thumbnail">
                                <img src="assets/images/samples/single-player-img1.jpg" alt="" />
                            </figure>
							<div class="card__content">
								<header class="post__header">
									<h2 class="post__title">NARAYAN THAKUR                                    </h2>
								</header>
								<div class="post__content">
									<p>As a child, Narayan Thakur was fascinated by the prospect of playing Cricket professionally, but due to financial instability he eventually made the decision of shifting to track and field events. He began idolising Usain Bolt and Justin Gatlin, and decided that he would do his utmost to succeed at athletics, despite his physical limitations. To facilitate his dream, he has worked as a waiter and even as a DTC Bus cleaner for his livelihood. When he started training on the track, he trained without a coach for 2 years due to his financial situation but this did not deter him. From 2015 onwards,he began performing well and winning medals on the National circuit, exceeding people’s expectations of him and leaving his mark wherever he ran. The pinnacle of his career so far came in the 2018 Asian Para Games, where he created history by becoming the first Indian para athlete to win a Gold medal in the 100m event at the Asian Para Games. Motivated by his achievement, he went on to win several medals at national and international events. Narayan’s story of rising against adversity is beyond inspirational.</p>
									<div class="spacer"></div>
									<h4>Achievements</h4>
									<p><ul>
                            
                                
                            
                                
                                    
                            <li>Bronze medals in 100m and 200m at the Hangzhou 2022 Asian Para Games</li>
                        
                            <li>Won Gold and Silver Medal in 100m &amp; 200m at the 5th Indian Open National Para Athletics Championship, 2023</li>
                        
                            <li>Won Gold and Silver Medal in 100m &amp; 200m at the 4th Indian Open National Para Athletics Championship, 2022</li>
                        
                            <li>Bronze medal in 100m and finished 4th in 200m event at USA Open Championship - Desert Challenge Games 2022, Arizona, USA</li>
                        
                            <li>Gold medals in 100m and 200m event in 3rd Indian Open National Para Athletics Championship 2021, Bengaluru</li>
                        
                            <li>1st position in the 100m event at the Asian Para Games 2018</li>
                        
                    
                
             
            </ul></p>
									
									 
									 
								</div>
							</div>
						</article>


                        <div className="card card--clean">
                        <header className="card__header card__header--has-btn">
      <h4>Gallery</h4><a href="#" className="btn btn-default btn-outline btn-xs card-header__button">See All Posts</a>
    </header>
    <div className="card__content">
			<div class="album-wrapper">
				<div class="album js-album-masonry row">
					<div class="album__item col-xs-6 col-sm-4">
						<div class="album__item-holder"><a href="../assets/images/4.jpg"
								class="album__item-link mp_gallery">
								<figure class="album__thumb"><img src={"../assets/images/4.jpg"} style={{height:"180px"}} alt=""/>
								</figure>
								<div class="album__item-desc album__item-desc--bottom-left"><span
										class="album__item-icon"><span class="icon-camera"></span></span>
									<div class="album__item-desc-inner">
										<h4 class="album__item-title">The team is taking a summer vacation on Woody
											Valley</h4><time class="album__item-date" datetime="2016-08-23">August 23rd,
											2016</time>
									</div>
								</div>
							</a></div>
					</div>
					<div class="album__item col-xs-6 col-sm-4">
						<div class="album__item-holder"><a href="../assets/images/2.jpg"
								class="album__item-link mp_gallery">
								<figure class="album__thumb"><img src="../assets/images/2.jpg"  style={{height:"180px"}} alt=""/>
								</figure>
								<div class="album__item-desc album__item-desc--bottom-left"><span
										class="album__item-icon"><span class="icon-camera"></span></span>
									<div class="album__item-desc-inner">
										<h4 class="album__item-title">Mark Johnson has a Tibia Fracture and is gonna be
											out</h4><time class="album__item-date" datetime="2016-08-23">August 23rd,
											2016</time>
									</div>
								</div>
							</a></div>
					</div>
					<div class="album__item col-xs-6 col-sm-4">
						<div class="album__item-holder"><a href="../assets/images/3.jpg"
								class="album__item-link mp_gallery">
								<figure class="album__thumb"><img src="../assets/images/3.jpg" style={{height:"180px"}}   alt=""/>
								</figure>
								<div class="album__item-desc album__item-desc--bottom-left"><span
										class="album__item-icon"><span class="icon-camera"></span></span>
									<div class="album__item-desc-inner">
										<h4 class="album__item-title">Cheerleader tryouts will start next Friday at 5pm
										</h4><time class="album__item-date" datetime="2016-08-23">August 23rd,
											2016</time>
									</div>
								</div>
							</a></div>
					</div>

                    <div class="album__item col-xs-6 col-sm-4">
						<div class="album__item-holder"><a href="../assets/images/8.jpg"
								class="album__item-link mp_gallery">
								<figure class="album__thumb"><img src={"../assets/images/8.jpg"} style={{height:"180px"}} alt=""/>
								</figure>
								<div class="album__item-desc album__item-desc--bottom-left"><span
										class="album__item-icon"><span class="icon-camera"></span></span>
									<div class="album__item-desc-inner">
										<h4 class="album__item-title">The team is taking a summer vacation on Woody
											Valley</h4><time class="album__item-date" datetime="2016-08-23">August 23rd,
											2016</time>
									</div>
								</div>
							</a></div>
					</div>
                    <div class="album__item col-xs-6 col-sm-4">
						<div class="album__item-holder"><a href="../assets/images/5.jpg"
								class="album__item-link mp_gallery">
								<figure class="album__thumb"><img src="../assets/images/5.jpg" style={{height:"180px"}}   alt=""/>
								</figure>
								<div class="album__item-desc album__item-desc--bottom-left"><span
										class="album__item-icon"><span class="icon-camera"></span></span>
									<div class="album__item-desc-inner">
										<h4 class="album__item-title">Cheerleader tryouts will start next Friday at 5pm
										</h4><time class="album__item-date" datetime="2016-08-23">August 23rd,
											2016</time>
									</div>
								</div>
							</a></div>
					</div>
					<div class="album__item col-xs-6 col-sm-4">
						<div class="album__item-holder"><a href="../assets/images/2.jpg"
								class="album__item-link mp_gallery">
								<figure class="album__thumb"><img src="../assets/images/2.jpg"  style={{height:"180px"}} alt=""/>
								</figure>
								<div class="album__item-desc album__item-desc--bottom-left"><span
										class="album__item-icon"><span class="icon-camera"></span></span>
									<div class="album__item-desc-inner">
										<h4 class="album__item-title">Mark Johnson has a Tibia Fracture and is gonna be
											out</h4><time class="album__item-date" datetime="2016-08-23">August 23rd,
											2016</time>
									</div>
								</div>
							</a></div>
					</div>
					
				 
				</div>
			</div> 
			</div> 
			  
		</div>
 
 

                    <Paralympic_News></Paralympic_News>
					</div> 
					<div class="sidebar sidebar--player col-lg-4"> 
						<aside class="widget card widget--sidebar widget-newslog">
							<div class="widget__title card__header">
								<h4>Player Newslog</h4>
							</div>
							<div class="widget__content card__content">
								<ul class="newslog">
									<li class="newslog__item newslog__item--injury">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli’s</strong> knee
												fracture is healing properly and will be returning to the field
												<strong>next week</strong>.</div><time class="newslog__date"
												datetime="2021-01-19">January 19, 2016</time>
										</div>
									</li>
									<li class="newslog__item newslog__item--injury">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli</strong> has a knee
												fracture and he’s gonna be out of the play for <strong>4
													months</strong>.</div><time class="newslog__date"
												datetime="2021-01-19">September 26, 2016</time>
										</div>
									</li>
									<li class="newslog__item newslog__item--join">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli</strong> is now the
												<strong>1st Shooting Guard</strong> after being 3rd Shooting Guard for
												the past year.</div><time class="newslog__date"
												datetime="2021-01-19">September 6, 2016</time>
										</div>
									</li>
									<li class="newslog__item newslog__item--injury">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli</strong> has an
												abductor strain so he will miss the next game against the LA Pirates.
											</div><time class="newslog__date" datetime="2021-01-19">March 4, 2016</time>
										</div>
									</li>
									<li class="newslog__item newslog__item--injury">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli</strong> has an
												abductor strain so he will miss the next game against the LA Pirates.
											</div><time class="newslog__date" datetime="2021-01-19">March 4, 2016</time>
										</div>
									</li>
									<li class="newslog__item newslog__item--award">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli</strong> won the
												<strong>Player of the Year 2014 Award</strong> for the first time.</div>
											<time class="newslog__date" datetime="2021-01-19">August 17, 2015</time>
										</div>
									</li>
									<li class="newslog__item newslog__item--injury">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli</strong> has a lower
												back contusion and will miss the next two games.</div><time
												class="newslog__date" datetime="2021-01-19">August 5, 2015</time>
										</div>
									</li>
									<li class="newslog__item newslog__item--join">
										<div class="newslog__item-inner">
											<div class="newslog__content"><strong>James Girobilli</strong> has joined
												the team after playing for the California Surfers for a year.</div><time
												class="newslog__date" datetime="2021-01-19">June 19, 2013</time>
										</div>
									</li>
								</ul>
							</div>
						</aside> 
						<aside class="widget widget--sidebar card widget-newsletter">
							<div class="widget__title card__header">
								<h4>Our Newsletter</h4>
							</div>
							<div class="widget__content card__content">
								<h5 class="widget-newsletter__subtitle">Subscribe Now!</h5>
								<div class="widget-newsletter__desc">
									<p>Receive the latest news from the team: game reminders, new adquisitions and
										professional match results.</p>
								</div>
								<form action="#" id="newsletter" class="inline-form">
									<div class="input-group">
                                        <input type="email" class="form-control"
											placeholder="Your email address..."/> <span
											class="input-group-append"><button class="btn btn-lg btn-default"
												type="button">Send</button></span></div>
								</form>
							</div>
						</aside> 
					</div> 
				</div>
			</div>
		</div>
    
    </>
  )
}
