import React, { Component, useEffect, useState, Text } from 'react'
import axios from 'axios'
import ApiConfig from '../../ApiConfig';

 
export default function Header_Live_Matches() {

    const apikey = ApiConfig.cricket_api_key;
    const api_url = ApiConfig.api_url;
    const [recent, setRecent] = useState([])
 
    
    const getAnswer = async () => {
      axios.get("https://rest.entitysport.com/v2/matches/?status=3&token=bf9e12cc4c8f3fbb4c457c9062b84492")
      .then((response) => {
      //  console.log(response);
      setRecent(response.data.response.items)
      })
  };

 
  useEffect(() => {
             getAnswer();
          
          },[]);



  return (
    <div className='row'>
              {recent.length ?(recent.slice(0, 4).map((value,index) => {
                
                return ( 
                           <div className='col-sm-3'>
                           <div  className="card header_score_box" id={value.match_id}>
                           <div className="card-body p-2  ">
                            <a href={"/live-cricket-score/"+ value.match_id} >
                           <table width="100%">
                                   <tbody>
                                   <tr>
                                           <td colSpan={2} className="text-left match_title"> {value.subtitle}, {value.title}</td>
                                           
                                       </tr>
                                       <tr>
                                           <td align='left'>  <img   alt="team logo" src={value.teama.logo_url} className="team-img-icon width-img" /> {value.teama.short_name}</td>
                                           <td align='right'> {value.teama.scores}</td>
                                       </tr>
                                       <tr>
                                           <td align='left'> <img   alt="team logo" src={value.teamb.logo_url} className="team-img-icon  width-img" /> {value.teamb.short_name}</td>
                                           <td align='right'> {value.teamb.scores}</td>
                                       </tr>
                                       <tr>
                                           <td colSpan={2} className="text-left match_status"> {value.status_note}</td>
                                           
                                       </tr>
                                       <tr>
                                           <td colSpan={2} className="text-left">
                                               <div className='d-flex justify-content-start header_score_note'>
                                                   <div className='header_score_status'><a href={"/comptition-schedule/"+value.competition.cid}>Schedule</a></div>
                                                   <div className='header_score_status'><a href={"/points-table/"+value.competition.cid}>Table</a></div>
                                                   {/* <div className='header_score_status'><a href="#">Report</a></div> */}
                                               </div> 
                                                </td>
                                           
                                       </tr>
                                   </tbody>
                           </table>
                           </a>
                           </div>
                           </div>
                           </div>
                              );
                
                                      })
                                  ) : (
                                    <p className='text-white'>No Live Match</p>
                                  ) } 
 
 
  </div>
  )
}
