import React from 'react'

export default function Para_Player_tab() {
  return (
    <>    <nav class="content-filter">
    <div class="container">
      <a href="#" class="content-filter__toggle"></a>
      <ul class="content-filter__list">
        <li class="content-filter__item content-filter__item--active">
          <a href="#" class="content-filter__link"><small>Player</small>Overview</a>
        </li>
        <li class="content-filter__item">
          <a href="#" class="content-filter__link"><small>Player</small>Full Statistics</a>
        </li>
        <li class="content-filter__item">
          <a href="#" class="content-filter__link"><small>Player</small>Biography</a>
        </li>
        <li class="content-filter__item">
          <a href="#" class="content-filter__link"><small>Player</small>Related News</a>
        </li>
        <li class="content-filter__item">
          <a href="#" class="content-filter__link"><small>Player</small>Gallery</a>
        </li>
      </ul>
    </div>
  </nav></>
  )
}
