import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SideBar from "../Components/SideBar";

export default function LiveMatches() {
    const [news, setNews] = useState([])
     
    const getAnswer = async () => {
        axios.get("https://rest.entitysport.com/v2/matches/?status=3&token=bf9e12cc4c8f3fbb4c457c9062b84492")
        .then((response) => {
        //  console.log(response);
        setNews(response.data.response.items)
        })
    };
    useEffect(() => {
               getAnswer();
            });
  

   //  const navigate = useNavigate();
   //  const handleClick = (data) => {
   //    axios.get("https://rest.entitysport.com/v2/matches/"+data.match_id+"/live?token=bf9e12cc4c8f3fbb4c457c9062b84492")
   //      .then((response) => {
   //            navigate('/live-score',{
   //                state: response.data.response
   //     });
           
   //        })
     
   //  }

  
  return (
    <>
     {/* <div class="page-heading">   
   <div class="container">
      <div class="row">
         <div class="col-md-10 offset-md-1">
            <h1 class="page-heading__title">Live <span class="highlight"> Cricket Matches</span></h1>
            <ol class="page-heading__breadcrumb breadcrumb">
               <li class="breadcrumb-item"><a href="/">Home</a></li>
               <li class="breadcrumb-item active" aria-current="page">Live Cricket Matches</li>
            </ol>
         </div>
      </div>
   </div>
</div> */}
<div class="site-content">
   <div class="container">
      <div class="row">
        			
         <div class="content col-md-8">
      					
            <div class="card">
               <header class="card__header card__header--has-btn">
                  <h4>Live Cricket Matches</h4>
               </header>
               {
            
            news.map((value,index) => {
              
              return (
               <div class="card__content">
                 
            <div class="game-result">
            <a href={"/live-cricket-score/"+ value.match_id}>


            <header class="game-result__header">
            <h3 class="game-result__title"> {value.title}, 
                                        {value.subtitle}</h3>
            <time class="game-result__date"  >
            {value.date_start}
            </time>
            </header>
            <div class="game-result__content"> 
            <div class="game-result__team game-result__team--first">
            <figure class="game-result__team-logo"> <img src={value.teama.logo_url} alt="" width="50"/> </figure>
            <div class="game-result__team-info">
            <h5 class="game-result__team-name">{value.teama.scores}</h5>
            
            </div>
            </div>

            <div class="game-result__score-wrap">
            <div class="game-result__score"> 
            <span class="game-result__score-result game-result__score-result--winner">{value.teama.short_name} </span> <span class="game-result__score-dash">Vs</span> 
            <span class="game-result__score-result game-result__score-result--loser">{value.teamb.short_name}</span> </div>
            <div class="game-result__score-label">{value.live}</div>
            </div>

            <div class="game-result__team game-result__team--second">
            <figure class="game-result__team-logo"> <img src={value.teamb.logo_url} alt="" width="50"/> </figure>
            <div class="game-result__team-info">
            <h5 class="game-result__team-name">{value.teamb.scores}</h5>
            
            </div>
            </div>
            </div>
            
            </a>

            </div>
            <hr></hr>
            </div>

);
})
} 

</div>


</div>
<div id="sidebar" className="sidebar col-md-4">
   <SideBar></SideBar>
   </div>


</div></div></div>
        
    </>
  )
}
