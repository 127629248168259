import React, { useState } from 'react'
import axios from 'axios'
import News from './News'
import IplNews from './IplNews'
import LiveScore from '../Matches/LiveScore'
import LiveMatches from '../Matches/LiveMatches'

import BannerNews from './BannerNews'
import HomeScore from '../Matches/HomeScore'
import HomeNews from './HomeNews'
import SideBar from './SideBar'
 import AllHomeScore from './AllHomeScore'
import HomeLink from './HomeLink'
import Top_News from './Top_News'
import HomeRadio from './HomeRadio'
import HomeVideos from '../Videos/HomeVideos'
import HomeVideosShort from '../Videos/HomeVideosShort'
import { Helmet } from 'react-helmet';
import Header_Tab from '../Matches/Header_Component/Header_Tab'


 export default function Home() {
    
   return (
       <>
        <Helmet>
        <title>Cricplex | Live Radio Commentary, Score, Schedule, Latest News</title>
        <meta name="description" content="Listen live cricket commentary with DexSportsRadio.com ,  Get Live Cricket Score, Scorecard, Schedules of  International or Domestic sports matches along with Latest News with Dex Sports Radio" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="Cricplex | Live Radio Commentary, Score, Schedule, Latest News" />
        <meta property="og:description" content="Listen live cricket commentary with DexSportsRadio.com ,  Get Live Cricket Score, Scorecard, Schedules of  International or Domestic sports matches along with Latest News with Dex Sports Radio" />
        <meta property="og:image" content="https://www.cricplex.com/assets/images/cricplex.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="Cricplex | Live Radio Commentary, Score, Schedule, Latest News" />
        <meta name="twitter:description" content="Listen live cricket commentary with DexSportsRadio.com ,  Get Live Cricket Score, Scorecard, Schedules of  International or Domestic sports matches along with Latest News with Dex Sports Radio" />
        <meta name="twitter:image" content="https://www.cricplex.com/assets/images/cricplex.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='site-wrapper header_match'>
      <div className='container-fluid'>
    <Header_Tab></Header_Tab>
    </div>
    </div>
    <HomeLink></HomeLink>
    <div className='container_fluid mt-2  '>
    <Top_News></Top_News>
    </div>
 <div className='radio_section'>
 <div className='container_fluid  p-2'>
 <div class="posts posts--cards post-grid row">
 <div class="post-grid__item col-sm-4">
 <div className="widget__content  home_ads">
                        <img src={"/assets/images/ads/1.jpg"} alt="500 Fx"  width="100%" />
                        </div>
  </div>
  <div class="post-grid__item col-sm-4">
    <HomeRadio></HomeRadio>
  </div>
  <div class="post-grid__item col-sm-4">
  <div className="widget__content home_ads ">
                        <img src={"/assets/images/ads/2.jpg"} alt="500 Fx"  width="100%" />
                        </div>
  </div>
  </div> 
 </div>
 </div>
 <div className='container_fluid  p-2'>
 <HomeVideos></HomeVideos>
 <HomeVideosShort></HomeVideosShort>
 </div>
 {/* <AllHomeScore></AllHomeScore> */}
 <HomeNews></HomeNews>

   
     </>
   )
 }
 