import React, { useEffect, useState, Text } from "react";
import { Link, useNavigate } from 'react-router-dom'
import AjmanCup from './AjmanCup';
import axios from 'axios'

export default function All_Iplnews() {
    const [news, setNews] = useState([])
   
    // axios.get("https://cricplex.com/example/news")
    //   .then((response) => {
    //   //  console.log(response);
    //     setNews(response.data.data)
    //   })
  
      const getAnswer = async () => {
        axios.get("https://admin.cricplex.com/example/ajmancup")
        .then((response) => {
        //  console.log(response);
          setNews(response.data.data)
        })
    };
    useEffect(() => {
               getAnswer();
            });
       
            const navigate = useNavigate();
            const handleClick = (data) => {
              axios.get("https://admin.cricplex.com/example/avrnewsdetail/"+data.id)
                .then((response) => {
                      navigate('/news-detail',{
                          state: response.data.data
               });
                   
                  })
             
            }

  return (
    <>
        
        <section className="container">
                <div className="bg-body box-layout">
                    <div className="section-space-less30">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="topic-border color-scampi mb-30">
                                    <div className="topic-box-lg color-scampi">Ajman Cup 2022</div>
                                </div>
                                <div className="row">
                                {
            
            news.map((value,index) => {
              
              return (
                                    <div className="col-xl-12 col-lg-6 col-md-6 col-sm-12">
                                        <div className="media media-none--lg mb-30">
                                            <div className="position-relative width-40">
                                                <a onClick={()=>handleClick(value)} className="img-opacity-hover">
                          <img src={"https://admin.cricplex.com/news_images/"+ value.image} alt="news" className="img-fluid"/>
                                                </a>
                                                <div className="topic-box-top-xs">
                                                    <div className="topic-box-sm color-cod-gray mb-20">{value.category}</div>
                                                </div>
                                            </div>
                                            <div className="media-body p-mb-none-child media-margin30">
                                                <div className="post-date-dark">
                                                    <ul>
                                                        <li>
                                                            <span>by</span>
                                                            <a href="#">{value.createdby}</a>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <i className="fa fa-calendar" aria-hidden="true"></i>
                                                            </span>{value.createdate}</li>
                                                    </ul>
                                                </div>
                                                <h3 className="title-semibold-dark size-lg mb-15">
                                                    <a onClick={()=>handleClick(value)}>{value.heading}</a>
                                                </h3>
                                                <p>{value.summery}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                        );
                                    })
                                  } 
                                </div>
                            </div>
                            <div className="ne-sidebar sidebar-break-md col-lg-4 col-md-12">
                            <div className="sidebar-box">
                                    <div className="ne-banner-layout1 text-center">
                                        <a href="#">
                                            <img src="/assets/img/banner/avrpay_ads.jpg" alt="ad" className="img-fluid"/>
                                        </a>
                                    </div>
                                </div>
                                <div className="sidebar-box">
                                    <div className="topic-border color-cod-gray mb-30">
                                        <div className="topic-box-lg color-cod-gray">Categories</div>
                                    </div>
                                    <ul className="archive-list">
                                        <li>
                                            <a href="#">IPL 2022
                                                <span> (12)</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">Ajman T20 Cup 
                                                <span> (04)</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">Latest News
                                                <span> (11)</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">T10 News
                                                <span> (79)</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">Cricket News
                                                <span> (05)</span>
                                            </a>
                                        </li>
                                         
                                    </ul>
                                </div>
                              

                             <AjmanCup></AjmanCup>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}
