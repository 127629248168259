const ApiConfig = {
    cricket_url: "https://rest.entitysport.com/v2/",
    cricket_api_key: "bf9e12cc4c8f3fbb4c457c9062b84492",
    news_api:"https://admin.cricplex.com/mobileapi/top10news"

}

export default ApiConfig


 