import React, { useState } from 'react'
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import axios from 'axios'
import moment from "moment";    
import SideBar from '../Components/SideBar';


export default function ScheduleMatches() {
    const [news, setNews] = useState([])
   
    axios.get("https://rest.entitysport.com/v2/matches/?status=1&token=bf9e12cc4c8f3fbb4c457c9062b84492&pre_squad=true&per_page=50")
      .then((response) => {
        //console.log(response);
        setNews(response.data.response.items)
      }) 
  
  return (
    <>



       	{/* <div class="page-heading">
			<div class="container">
				<div class="row">
					<div class="col-md-10 offset-md-1">
						<h1 class="page-heading__title">Schedule <span class="highlight">& Matches</span></h1>
						<ol class="page-heading__breadcrumb breadcrumb">
							<li class="breadcrumb-item"><a href="/">Home</a></li>
							<li class="breadcrumb-item active" aria-current="page">Schedule &amp; Matches</li>
						</ol>
					</div>
				</div>
			</div>
		</div> */}

   
    <div class="site-content">
				<div class="container">
				<div class="row">
				<div class="content col-md-8">
                   
				<div class="card card--clean">
        <div class="card__header">
						<h4>Schedule Matches </h4>
					</div>
        <Tabs
          activeTab="1"
          className=""
          ulClassName="schedule"
          activityClassName="bg-success"
          onClick={(event, tab) => console.log(event, tab)}
        >
            <Tab title="International" className="mr-3">
            {
                                    news.map((value,index) => {
                                      if (value.competition.category== 'international') {
                                         return (
   <div className="card widget__content card__content">
 <a href={"/match-Schedule/"+ value.match_id} >
   <div className="match-preview match-preview--alt">
    <section className="match-preview__body">
      <header className="match-preview__header">
        <h3 className="match-preview__title">{value.competition.title}</h3>
        <time className="match-preview__date" dateTime={value.date_start}>{value.date_start}</time>
        </header>
        <div className="match-preview__content">{/* 1st Team */}
        <div className="match-preview__team match-preview__team--first">
          <figure className="match-preview__team-logo">
            <img src={value.teama.logo_url} width="60px"  alt  />
            </figure>
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teama.short_name}</h5>
            <div className="match-preview__team-info">{value.teama.name}</div>
            </div>
        </div>
        {/* 1st Team / End */}
        <div className="match-preview__vs">
          <div className="match-preview__conj">VS</div>
          </div>{/* 2nd Team */}
          <div className="match-preview__team match-preview__team--second">
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teamb.short_name}</h5>
              <div className="match-preview__team-info">{value.teamb.name}</div>
              </div>
              <figure className="match-preview__team-logo"><img src={value.teamb.logo_url} width="60px" alt /></figure>
              </div>
              {/* 2nd Team / End */}</div>
        
        {/* Call to Action */}
        <div className="match-preview__action">
          <div className="match-preview__match-info"><time className="match-preview__match-time" dateTime={value.date_start}>
            
          <span>{moment(value.date_start, 'YYYY-MM-DD hh:mm:ss').format('HH:MM')}</span>  IST </time><div className="match-preview__match-place">{value.venue.name}, {value.venue.location}</div>
          </div>
          <div className="match-preview__action-btn" ><a href="#" style={{width:"50px", float:"right"}} className="btn  btn-block">&rarr;</a></div>
        </div>
        {/* Call to Action / End */}</section>
     </div>
     
     </a></div>
  )};
})
}   
						 
                       

      
            </Tab>
            <Tab title="T20I" className="mr-3">
                <div className="mt-3">
                {
                                    news.map((value,index) => {
                                      if (value.format_str== 'T20I') {
                                         return (
   <div className="card widget__content card__content">
 <a href={"/match-Schedule/"+ value.match_id} >
   <div className="match-preview match-preview--alt">
    <section className="match-preview__body">
      <header className="match-preview__header">
        <h3 className="match-preview__title">{value.competition.title}</h3>
        <time className="match-preview__date" dateTime={value.date_start}>{value.date_start}</time>
        </header>
        <div className="match-preview__content">{/* 1st Team */}
        <div className="match-preview__team match-preview__team--first">
          <figure className="match-preview__team-logo">
            <img src={value.teama.logo_url} width="60px"  alt  />
            </figure>
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teama.short_name}</h5>
            <div className="match-preview__team-info">{value.teama.name}</div>
            </div>
        </div>
        {/* 1st Team / End */}
        <div className="match-preview__vs">
          <div className="match-preview__conj">VS</div>
          </div>{/* 2nd Team */}
          <div className="match-preview__team match-preview__team--second">
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teamb.short_name}</h5>
              <div className="match-preview__team-info">{value.teamb.name}</div>
              </div>
              <figure className="match-preview__team-logo"><img src={value.teamb.logo_url} width="60px" alt /></figure>
              </div>
              {/* 2nd Team / End */}</div>
        
        {/* Call to Action */}
        <div className="match-preview__action">
          <div className="match-preview__match-info"><time className="match-preview__match-time" dateTime={value.date_start}>
            
          <span>{moment(value.date_start, 'YYYY-MM-DD hh:mm:ss').format('HH:MM')}</span>  IST </time><div className="match-preview__match-place">{value.venue.name}, {value.venue.location}</div>
          </div>
          <div className="match-preview__action-btn" ><a href="#" style={{width:"50px", float:"right"}} className="btn  btn-block">&rarr;</a></div>
        </div>
        {/* Call to Action / End */}</section>
     </div></a></div>
  )};
})
}
                </div>
            </Tab>
            <Tab title="Domestic" className="mr-3">
                <div className="mt-3">
                {
                                    news.map((value,index) => {
                                      if (value.competition.category == 'domestic') {
                                         return (
   <div className="card widget__content card__content">
 <a href={"/match-Schedule/"+ value.match_id} >
   <div className="match-preview match-preview--alt">
    <section className="match-preview__body">
      <header className="match-preview__header">
        <h3 className="match-preview__title">{value.competition.title}</h3>
        <time className="match-preview__date" dateTime={value.date_start}>{value.date_start}</time>
        </header>
        <div className="match-preview__content">{/* 1st Team */}
        <div className="match-preview__team match-preview__team--first">
          <figure className="match-preview__team-logo">
            <img src={value.teama.logo_url} width="60px"  alt  />
            </figure>
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teama.short_name}</h5>
            <div className="match-preview__team-info">{value.teama.name}</div>
            </div>
        </div>
        {/* 1st Team / End */}
        <div className="match-preview__vs">
          <div className="match-preview__conj">VS</div>
          </div>{/* 2nd Team */}
          <div className="match-preview__team match-preview__team--second">
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teamb.short_name}</h5>
              <div className="match-preview__team-info">{value.teamb.name}</div>
              </div>
              <figure className="match-preview__team-logo"><img src={value.teamb.logo_url} width="60px" alt /></figure>
              </div>
              {/* 2nd Team / End */}</div>
        
        {/* Call to Action */}
        <div className="match-preview__action">
          <div className="match-preview__match-info"><time className="match-preview__match-time" dateTime={value.date_start}>
            
          <span>{moment(value.date_start, 'YYYY-MM-DD hh:mm:ss').format('HH:MM')}</span>  IST </time><div className="match-preview__match-place">{value.venue.name}, {value.venue.location}</div>
          </div>
          <div className="match-preview__action-btn" ><a href="#" style={{width:"50px", float:"right"}} className="btn  btn-block">&rarr;</a></div>
        </div>
        {/* Call to Action / End */}</section>
     </div>
     </a>
     </div>
  )};
})
}
                </div>
            </Tab>

            <Tab title="Women" className="mr-3">
                <div className="mt-3">
                {
                                    news.map((value,index) => {
                                      if (value.competition.category == 'women') {
                                         return (
   <div className="card widget__content card__content">
   <a href={"/match-Schedule/"+ value.match_id} >
   <div className="match-preview match-preview--alt">
    <section className="match-preview__body">
      <header className="match-preview__header">
        <h3 className="match-preview__title">{value.competition.title}</h3>
        <time className="match-preview__date" dateTime={value.date_start}>{value.date_start}</time>
        </header>
        <div className="match-preview__content">{/* 1st Team */}
        <div className="match-preview__team match-preview__team--first">
          <figure className="match-preview__team-logo">
            <img src={value.teama.logo_url} width="60px"  alt  />
            </figure>
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teama.short_name}</h5>
            <div className="match-preview__team-info">{value.teama.name}</div>
            </div>
        </div>
        {/* 1st Team / End */}
        <div className="match-preview__vs">
          <div className="match-preview__conj">VS</div>
          </div>{/* 2nd Team */}
          <div className="match-preview__team match-preview__team--second">
            <div className="match-prevew__team-details">
              <h5 className="match-preview__team-name">{value.teamb.short_name}</h5>
              <div className="match-preview__team-info">{value.teamb.name}</div>
              </div>
              <figure className="match-preview__team-logo"><img src={value.teamb.logo_url} width="60px" alt /></figure>
              </div>
              {/* 2nd Team / End */}</div>
        
        {/* Call to Action */}
        <div className="match-preview__action">
          <div className="match-preview__match-info"><time className="match-preview__match-time" dateTime={value.date_start}>
            
          <span>{moment(value.date_start, 'YYYY-MM-DD hh:mm:ss').format('HH:MM')}</span>  IST </time><div className="match-preview__match-place">{value.venue.name}, {value.venue.location}</div>
          </div>
          <div className="match-preview__action-btn" ><a href="#" style={{width:"50px", float:"right"}} className="btn  btn-block">&rarr;</a></div>
        </div>
        {/* Call to Action / End */}</section>
     </div>
     </a>
     </div>
  )};
})
}
                </div>
            </Tab>
        </Tabs>
			 
					 
           </div>
           </div> 
                     <div id="sidebar" class="sidebar col-md-4">
        
           
            <SideBar></SideBar>
             
                          
             </div>
 
           </div> 
           </div> 
           </div>

    <div class="container">  
    <div class="site-content">
		
    
			
</div>
</div>
 
    </>
  )
}
