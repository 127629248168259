import React, { Component, useEffect, useState, Text } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import HomeRecentmatches from './HomeRecentmatches'
import { BrowserRouter as Router, useParams} from "react-router-dom";
import SideBar from '../Components/SideBar';

export default function HomePointsTable() {
    const [news, setNews] = useState([])
    const [CompDetail, setCompDetail] = useState([])
    const { id } = useParams();

    const getAnswer = async () => {
    axios.get('https://rest.entitysport.com/v2/competitions/'+id+'/standings/?token=bf9e12cc4c8f3fbb4c457c9062b84492')
      .then((response) => {
        //console.log(response);
        setNews(response.data.response.standings)
      },[])
    };

    const getAnswer_comp_detail = async () => {
      axios.get('https://rest.entitysport.com/v2/competitions/'+id+'/?token=bf9e12cc4c8f3fbb4c457c9062b84492')
        .then((response) => {
          //console.log(response);
          setCompDetail(response.data.response)
        },[])
      };

      useEffect(() => {
        getAnswer();
        getAnswer_comp_detail();
     
     },[]);
     // console.log('hello',news);
  return (
    <>
       <div class="site-content">
			<div class="container">
		
				<div class="row">
					 
					<div class="content col-md-8">
 <aside className="widget  widget--sidebar widget-standings">
      <div className="widget__title card__header card card__header--has-btn bg-white">
        <h4>{CompDetail.title}, {CompDetail.status},  {CompDetail.season}</h4>
        {/* <a href="#" className="btn btn-default btn-outline btn-xs card-header__button">See All Stats</a> */}
      </div>
     

        {
            news.map((value,index) => {
                
              return ( 
             <>
              <div className="widget__content card card__content">
              <div className="table-responsive">
          <table className="table table-hover table-standings">
            <thead>
              <tr ><td colSpan={5}>{value.round.name}</td></tr>
              <tr>
                <th> Team Positions</th>
                <th>W</th>
                <th>L</th>
                <th>RR</th>
                <th>PT</th>
              </tr>
            </thead>
            <tbody>
                               
             
            {
            value.standings.map((value,index) => {
                
              return ( 

              <tr>
                <td> 
                  <div className="team-meta">
                    <figure className="team-meta__logo"><img src={value.team.thumb_url} alt="L.A Pirates" /></figure>
                    <div className="team-meta__info">
                      <h6 className="team-meta__name">{value.team.abbr}</h6><span className="team-meta__place">{value.team.title}</span>
                    </div>
                  </div>
                </td>
                <td>{value.win}</td>
                <td>{value.loss}</td>
                <td>{value.netrr}</td>
                <td>{value.points}</td>
               
              </tr>
              
           
              );
              
            })
            } 
            
                          
                           
                </tbody>
          </table>
          </div>
          </div>
          </>  
 );
              
})
} 


       
    </aside> 
    </div>  
    <div id="sidebar" className="sidebar col-md-4">
<SideBar></SideBar>
   </div>
   </div>
					</div>
					</div>                 
    </>
  )
}
