import React, {useEffect, Fragment, useState} from 'react';
import {Link} from "react-router-dom";

export default function Paralympics() {
  return (
    <>
   <div class="page-heading">
			<div class="container">
				<div class="row">
					<div class="col-md-10 offset-md-1">
						<h1 class="page-heading__title"> <span class="highlight">Paralympics</span></h1>
						<ol class="page-heading__breadcrumb breadcrumb">
							<li class="breadcrumb-item"><a href="#">Home</a></li>
							<li class="breadcrumb-item active" aria-current="page">paralympics</li>
						</ol>
					</div>
				</div>
			</div>
		</div> 
    	<div class="site-content">
			<div class="container">
				<div class="sponsors-grid row">
					<div class="col-sm-6 col-lg-4">
						<div class="card sponsor-card">
							<header class="card__header sponsor-card__header" style={{height:"100%"}}>
								<figure class="sponsor-card__logo"><Link to="/paralympics/player-profile"><img
											src="../assets/images/narayan-thakur.jpg" alt="Sponsor Name #1"/></Link>
								</figure>
							</header>
							<div class="card__content sponsor-card__content text-center">
                                <h3>Narayan Thakur</h3>
								<div class="sponsor-card__excerpt">As a child, Narayan Thakur was fascinated by the prospect of playing Cricket professionally, but due to financial instability he eventually made the decision of shifting to track and field events....  </div>
								 
							</div>
							<footer class="card__footer sponsor-card__footer"><Link to="/paralympics/player-profile"
									class="sponsor-card__link">Know More</Link></footer>
						</div>
					</div>
					<div class="col-sm-6 col-lg-4">
						<div class="card sponsor-card">
							<header class="card__header sponsor-card__header" style={{height:"100%"}}>
								<figure class="sponsor-card__logo"><Link to="#"><img
											src="../assets/images/Amit-Kumar-Saroha.jpg" alt="Sponsor Name #2"/></Link>
								</figure>
							</header>
							<div class="card__content sponsor-card__content text-center">
                            <h3>Narayan Thakur</h3>
								<div class="sponsor-card__excerpt">A 3-time Paralympian and an Arjuna Awardee, Amit Kumar Saroha suffered a car accident when he was 22, causing him to become a quadriplegic due to compression of the spinal cord. Before his injury..... </div>
								 
							</div>
							<footer class="card__footer sponsor-card__footer"><Link href="#"
									class="sponsor-card__link">KNOW MORE</Link></footer>
						</div>
					</div>
					<div class="col-sm-6 col-lg-4">
						<div class="card sponsor-card">
							 

                            <header class="card__header sponsor-card__header" style={{height:"100%"}}>
								<figure class="sponsor-card__logo"><Link to="#"><img
											src="../assets/images/Ekta-Bhyan.jpg" alt="Sponsor Name #2"/></Link>
								</figure>
							</header>


							<div class="card__content sponsor-card__content text-center">
                            <h3>EKTA BHYAN</h3>
								<div class="sponsor-card__excerpt">In 2003, Ekta met with a road accident that injured her spinal cord, resulting in paralysis of her lower body. She started playing sports in 2014 as a means to get physically fit....</div>
								 
							</div>
							<footer class="card__footer sponsor-card__footer"><Link to="#"
									class="sponsor-card__link">Know More</Link></footer>
						</div>
					</div>
					 
				</div>
			</div>
		</div>
    
    </>
  )
}
