import React, { Component, useEffect, useState, Text } from 'react'
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

 import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import ApiConfig from '../ApiConfig';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function Test() {

    const apikey = ApiConfig.cricket_api_key;
    const api_url = ApiConfig.api_url;
    const [recent, setRecent] = useState([])
    
    const getAnswer = async () => {
      axios.get("https://rest.entitysport.com/v2/matches/?status=2&token=bf9e12cc4c8f3fbb4c457c9062b84492")
      .then((response) => {
      //  console.log(response);
      setRecent(response.data.response.items)
      })
  };
  useEffect(() => {
             getAnswer();
          });


  return (
    <div>
    
        <Tabs
          activeTab="1"
          className=""
          ulClassName=""
          activityClassName="bg-success"
          onClick={(event, tab) => console.log(event, tab)}
        >
            <Tab title="tab 1" className="mr-3">
                <div className="mt-3 container">
                <div> <Carousel responsive={responsive}  swipeable={false}
  >
                {recent.map((value,index) => {
                
                return ( 
                  <div className='p-1'>
                  <div  className="card header_score_box" id={value.match_id}>
                                   <div className="card-body p-2  ">
                               <table width="100%">
                                   <tbody>
                                   <tr>
                                           <td colSpan={2} className="text-left"> {value.subtitle}, {value.title}</td>
                                           
                                       </tr>
                                       <tr>
                                           <td align='left'>  <img   alt="team logo" src={value.teama.logo_url} className="team-img-icon width-img"/> {value.teama.short_name}</td>
                                           <td align='right'> {value.teama.scores}</td>
                                       </tr>
                                       <tr>
                                           <td align='left'> <img   alt="team logo" src={value.teamb.logo_url} className="team-img-icon  width-img"/> {value.teamb.short_name}</td>
                                           <td align='right'> {value.teamb.scores}</td>
                                       </tr>
                                       <tr>
                                           <td colSpan={2} className="text-left"> {value.status_note}</td>
                                           
                                       </tr>
                                       <tr>
                                           <td colSpan={2} className="text-left">
                                               <div className='d-flex justify-content-start header_score_note'>
                                                   <div className='header_score_status'><a href="#">Schedule</a></div>
                                                   <div className='header_score_status'><a href="#">Table</a></div>
                                                   <div className='header_score_status'><a href="#">Report</a></div>
                                               </div> 
                                                </td>
                                           
                                       </tr>
                                   </tbody>
                               </table>
        
                               </div>
                           </div>
                           </div>
                              
                              );
                
                                      })
                                    } 
  </Carousel></div>
                </div>
            </Tab>
            <Tab title="tab 2" className="mr-3">
                <div className="mt-3">
                <div> <Carousel responsive={responsive}  swipeable={false}
  >
                {recent.map((value,index) => {
                
                return ( 
                  <div className='p-1'>
                  <div  className="card header_score_box" id={value.match_id}>
                                   <div className="card-body p-2  ">
                               <table width="100%">
                                   <tbody>
                                   <tr>
                                           <td colSpan={2} className="text-left"> {value.subtitle}, {value.title}</td>
                                           
                                       </tr>
                                       <tr>
                                           <td align='left'>  <img   alt="team logo" src={value.teama.logo_url} className="team-img-icon width-img"/> {value.teama.short_name}</td>
                                           <td align='right'> {value.teama.scores}</td>
                                       </tr>
                                       <tr>
                                           <td align='left'> <img   alt="team logo" src={value.teamb.logo_url} className="team-img-icon  width-img"/> {value.teamb.short_name}</td>
                                           <td align='right'> {value.teamb.scores}</td>
                                       </tr>
                                       <tr>
                                           <td colSpan={2} className="text-left"> {value.status_note}</td>
                                           
                                       </tr>
                                       <tr>
                                           <td colSpan={2} className="text-left">
                                               <div className='d-flex justify-content-start header_score_note'>
                                                   <div className='header_score_status'><a href="#">Schedule</a></div>
                                                   <div className='header_score_status'><a href="#">Table</a></div>
                                                   <div className='header_score_status'><a href="#">Report</a></div>
                                               </div> 
                                                </td>
                                           
                                       </tr>
                                   </tbody>
                               </table>
        
                               </div>
                           </div>
                           </div>
                              
                              );
                
                                      })
                                    } 
  </Carousel></div>
                </div>
            </Tab>
            <Tab title="tab 3" className="mr-3">
                <div className="mt-3">
                    Tab 3 content
                </div>
            </Tab>
        </Tabs>

       
    </div>
  )
}
