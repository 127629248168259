import React, { Component, useEffect, useState, Text } from 'react'
import { BrowserRouter as Router, useParams} from "react-router-dom";

import axios from 'axios'
import SideBar from '../Components/SideBar';
import Scorecard from './Scorecard';
import ScoreTopSection from './ScoreTopSection';

export default function LiveScore() {

  const { id } = useParams();

  const [match, SetMatch] = useState([]);
  const [teama,setTeama] = useState([]);
  const [teamb,setTeamb] = useState([]);
  const [live_inning,setinnings] = useState([]);
  const [batsmen0,setbatsmen0] = useState([]);
  const [batsmen1,setbatsmen1] = useState([]);
  const [bowlers0,setbowlers0] = useState([]);
  const [bowlers1,setbowlers1] = useState([]);
  const [last_wicket,setlast_wicket] = useState([]);
  const [equations,setequations] = useState([]);
  const [commentaries,Setcommentaries] = useState([]);
const [items, setItems] = useState([{}]);
// console.log(batsmen1);
useEffect(() => {
    axios.get('https://rest.entitysport.com/v2/matches/'+id+'/live?token=bf9e12cc4c8f3fbb4c457c9062b84492').then(response => {
      SetMatch(response.data.response);
      Setcommentaries(response.data.response.commentaries);
      setTeama(response.data.response.teams[0]);
      setTeamb(response.data.response.teams[1]);
      setinnings(response.data.response.live_inning);
      setlast_wicket(response.data.response.live_inning.last_wicket);
      setequations(response.data.response.live_inning.equations);
      setbatsmen0(response.data.response.batsmen[0]);
      setbatsmen1(response.data.response.batsmen[1]);
      setbowlers0(response.data.response.bowlers[0]);
      setbowlers1(response.data.response.bowlers[1]);
      
    }).catch(err => console.log(err));
}, [])


  return (
    <div>
     
     
    
       <section className="site-content" id={match.match_id}>
       <div className="container">
        <div className='row'>
        <div className='content col-md-8'>
                     <div className="card bg-white mb-20 p-20">
                        <div className="card-body">
                        
                         <ScoreTopSection></ScoreTopSection>
 
                         <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
             <table className="table table__cell-center table-thead-color live_player_table">
                <thead>
                   <tr >
                      <th>
                         BATSMAN
                      </th>
                      <th>
                         R
                      </th>
                      <th>
                         B 
                      </th>
                      <th>
                         4S
                      </th>
                      <th>
                         6S
                      </th>
                      <th>
                         SR
                      </th>
                   </tr>
                </thead>
                <tbody>
                   <tr >
                      <td>{batsmen0.name}</td>
                      <td>{batsmen0.runs}</td>
                      <td>{batsmen0.balls_faced}</td>
                      <td>{batsmen0.fours}</td>
                      <td>{batsmen0.sixes}</td>
                      <td>{batsmen0.strike_rate}</td>
                     
                   </tr>
                   <tr >
                      <td>{batsmen1.name}</td>
                      <td>{batsmen1.runs}</td>
                      <td>{batsmen1.balls_faced}</td>
                      <td>{batsmen1.fours}</td>
                      <td>{batsmen1.sixes}</td>
                      <td>{batsmen1.strike_rate}</td>
                     
                   </tr>
                </tbody>
             </table>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
             <table className="table table__cell-center table-thead-color live_player_table ">
                <thead>
                   <tr >
                      <th>Bowler</th>
                      <th>O</th>
                      
                      <th>R</th>
                      <th>M</th>
                      <th>W</th>
                      <th>ECON</th>
                   </tr>
                </thead>
                <tbody>
                   <tr >
                       <td>{bowlers0.name}</td>
                      <td>{bowlers0.overs}</td>
                      <td>{bowlers0.runs_conceded}</td>
                      <td>{bowlers0.maidens}</td>
                      <td>{bowlers0.wickets}</td>
                      <td>{bowlers0.econ}</td>
                   </tr>
                   <tr >
                      <td>{bowlers1.name}</td>
                      <td>{bowlers1.overs}</td>
                      <td>{bowlers1.runs_conceded}</td>
                      <td>{bowlers1.maidens}</td>
                      <td>{bowlers1.wickets}</td>
                      <td>{bowlers1.econ}</td>
                   </tr>
                </tbody>
             </table>
          </div>
       </div>
                     <div className="position-relative mb-50-r">
                        <div className="  bg-white" >
                           <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" data-toggle="tab" href="#livescoring">Commentry</a>
                              </li>
                               <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#scorecard">Scorecard</a>
                              </li>
                             {/* <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#menu2">Info</a>
                              </li> */}
                           </ul>
                           <div className="tab-content">
                              <div id="livescoring" className="tab-pane active">
                                 <br/> 
                                 <h6>CURRENT PARTNERSHIP:</h6>
                                 
                                 <p>{last_wicket.score_at_dismissal}, {last_wicket.name},  {last_wicket.how_out}</p>
                                 <h6>CURRENT RUN RATE:</h6>
                                 <p>{equations.runrate} | Last Ten Overs: {live_inning.last_ten_overs}</p>
                                 <h6>RECENT SCORE</h6>
                                 <p>{live_inning.recent_scores}</p>
                                 {
            
            commentaries.reverse().map((value,index) => {
              
              
              return (
                                 <div className="commentry">
                                {value.event=='overend' &&
                                 <table className="table mb-20 mt-20 overend" >
                                    <thead>
                                       <tr >
                                          <th colSpan="2" className="border border-info-10"><strong
                                             className="float-left"> END OF OVER : {value.over} ({value.runs}) </strong>
                                             <strong className="float-right">{match.live_inning.name} | {value.score}</strong>
                                          </th>
                                       </tr>
                                    </thead>
                                    {/* <tbody>
                                       <tr >
                                          <td><span className="float-left">  {value.bats[0].batsman_id}  </span>
                                             <span className="float-right">13</span>
                                          </td>
                                          <td><span className="float-left">{value.bowls[0].bowler_id}</span>
                                             <span className="float-right">2-0-31-2</span>
                                          </td>
                                       </tr>
                                       <tr >
                                          <td><span className="float-left">{value.bats[1].batsman_id}</span>
                                             <span className="float-right">{value.bowls[1].bowler_id}</span>
                                          </td>
                                          <td><span className="float-left">Alick Athanaze</span>
                                             <span className="float-right">2-0-9-1</span>
                                          </td>
                                       </tr>
                                    </tbody> */}
                                 </table>
               }     
                               {value.event!='overend' &&
                                
                                 <ul className="mt-10 d-flex pl-2" style={{ listStyleType: "none" }}>
                                 {(function() {
                                           if (value.score =='w') {
                                             return  <li className="circle red"  ><span >{value.score}</span></li>;
                                           }  if (value.score == 4) {
                                             return <li className="circle yellow"  ><span >{value.score}</span></li>;
                                           }  if (value.score == 6) {
                                             return <li className="circle green"  ><span >{value.score}</span></li>;
                                           }   if (value.score == 0) {
                                             return <li className="circle blue"  ><span >{value.score}</span></li>;
                                           }  
                                                      
                                           else {
                                             return <li className="circle no_color"  ><span >{value.score}</span></li>;
                                           }
                                         })()}
                                         
                                  
                                                                  {/* <li className="circle"  ><span >{value.score}</span></li> */}
                                 
                                                                     <li  >{value.over}.{value.ball} : {value.commentary}
                                                                     </li>
                                                                  </ul>
            }
                                 </div>
  );
})
} 

                                  
                              </div>
                              <div id="scorecard" className="tab-pane">
                              <br/> 
                           <Scorecard></Scorecard>
                              </div>
                           </div>
                        </div>
                     </div>
                   
                  
                
                        </div>
                     </div>
                     </div>
                     <div id="sidebar" className="sidebar col-md-4">
                      <SideBar></SideBar>
                     </div>
                     </div>
                  </div>
         </section>
            
    </div>
  )
}
