import React, { Component, useEffect, useState, Text } from 'react'

import { BrowserRouter as Router, useParams} from "react-router-dom";

import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import axios from 'axios'
import moment from "moment";    
import SideBar from '../../Components/SideBar';


export default function SeriesMatches() {
  const { id } = useParams();

    const [news, setNews] = useState([])
    const [CompDetail, setCompDetail] = useState([])
    
    const getAnswer = async () => {
    axios.get('https://rest.entitysport.com/v2/competitions/'+id+'/matches/?token=bf9e12cc4c8f3fbb4c457c9062b84492&per_page=50&&paged=1')
    .then((response) => {
        //console.log(response);
        setNews(response.data.response.items)
      }) 
    };

      const getAnswer_comp_detail = async () => {
        axios.get('https://rest.entitysport.com/v2/competitions/'+id+'/?token=bf9e12cc4c8f3fbb4c457c9062b84492')
          .then((response) => {
            //console.log(response);
            setCompDetail(response.data.response)
          },[])
        };
  
        useEffect(() => {
          getAnswer();
          getAnswer_comp_detail();
       
       },[]);
  
  return (
    <>



       	{/* <div class="page-heading">
			<div class="container">
				<div class="row">
					<div class="col-md-10 offset-md-1">
						<h1 class="page-heading__title">Schedule <span class="highlight">& Matches</span></h1>
						<ol class="page-heading__breadcrumb breadcrumb">
							<li class="breadcrumb-item"><a href="/">Home</a></li>
							<li class="breadcrumb-item active" aria-current="page">Schedule &amp; Matches</li>
						</ol>
					</div>
				</div>
			</div>
		</div> */}

   
    <div class="site-content">
				<div class="container">
				<div class="row">
				<div class="content col-md-8">

        <div id="primary" class="content-area">
			<main id="main" class="site-main">
				<div class="sp-template sp-template-event-list card card--has-table">
			<header class="card__header">
			<h4 class="sp-table-caption">   {CompDetail.title}, {CompDetail.status},  {CompDetail.season}</h4>
					</header>
		<div class="card__content">
		<div class="sp-table-wrapper table-responsive">
			<div class="sp-scrollable-table-wrapper"><div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer"><table class="table table-hover   team-schedule--full sp-event-list sp-event-list-format-title sp-data-table sp-paginated-table sp-sortable-table sp-scrollable-table dataTable no-footer" data-sp-rows="10" id="DataTables_Table_0" role="grid">
				<thead>
					<tr role="row">
            <th class="data-date sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Date">Date</th>
            <th class="data-event sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Game">Match Details</th>
            <th class="data-time sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Time">Time</th>
          
            
            </tr>
				</thead>
				<tbody>
        {
                                    news.map((value,index) => {
                                    
                                         return (
									<tr class="sp-row sp-post alternate sp-row-no-0 odd"   role="row">
                  <td class="data-date" itemprop="startDate" content="2024-10-27T11:00+00:00" data-label="Date">
                    <a href={"/match-Schedule/"+ value.match_id}  itemprop="url"><date>{value.date_start}</date> </a></td>
                  <td class="data-event" data-label="Game">
                    <a href={"/match-Schedule/"+ value.match_id} >
                      <span class="team-logo">
                    <img width="30" height="32" src={value.teama.logo_url} class="attachment-sportspress-fit-mini size-sportspress-fit-mini wp-post-image" alt="" loading="lazy" /></span>
                     <span class="team-logo">
                      <img width="28" height="32" src={value.teamb.logo_url} class="attachment-sportspress-fit-mini size-sportspress-fit-mini wp-post-image" alt="" loading="lazy"/></span>   <span>{value.teama.name} Vs {value.teamb.name}</span> 
                      </a> {value.subtitle}<br></br>
                      <span>Venue : {value.venue.name}, {value.venue.location}, {value.venue.country}</span> </td>
                      
                      <td class="data-time ok" data-label="Time"> <date>{moment(value.date_start, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}</date> </td>
                  
            

                 
                  </tr>
                  
                );
              })
              }   
                  </tbody>
			</table></div></div>
		</div>
			</div>
</div>

			</main>
		</div>


			 
           </div> 
                     <div id="sidebar" class="sidebar col-md-4">
        
           
            <SideBar></SideBar>
             
                          
             </div>
 
           </div> 
           </div> 
           </div>

    <div class="container">  
    <div class="site-content">
		
    
			
</div>
</div>
 
    </>
  )
}
