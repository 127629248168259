import React, { useEffect, useState } from "react"
import ReactDOM from 'react-dom';

import {
	BrowserRouter as Router,
	generatePath,
	Switch,
	Route,
	useHistory,
	useParams
  } from "react-router-dom";
import SideBar2 from "./Sidebar2";

const BlogDetail = () => {
// 	const queryString = window.location.pathname;
// 	console.log('mada', queryString);
//   const [user, setUser] = useState([])
//   const id = 1
const [blogid, setid] = useState([])
 const [heading, setHeading] = useState([])
  const [content, setcontent] = useState([])
 const [createdate, setcreatedate] = useState([])
 const [createdby, setcreatedby] = useState([])
 const [image, setimage] = useState([])
 const { id } = useParams();

//  const root = ReactDOM.createRoot(document.getElementById('root'));

//  const myElement = (
// 	<ul>
// 	  <li>Apples</li>
// 	  <li>Bananas</li>
// 	  <li>Cherries</li>
// 	</ul>
//   );

  const fetchData = () => {
	//https://admin.cricplex.com/example/avrnewsdetail/"+data.id
    fetch(`https://admin.cricplex.com/Mobileapi/blog_detail/${id}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setid(data.id)
        setHeading(data.heading)
          setcontent(data.content)
        setcreatedate(data.createdate)
        setcreatedby(data.createdby)
        setimage(data.image)
       })
  }
  const regex = /(<([^>]+)>)/gi;
  useEffect(() => {
    fetchData()

  }, [])
var contents = content.toString();
  
  return <>
  <div class="page-heading">
			<div class="container">
				<div class="row">
					<div class="col-md-10 offset-md-1">
						<h1 class="page-heading__title" style={{fontSize:"18px"}}><span class="highlight">{heading}</span></h1>
						<ol class="page-heading__breadcrumb breadcrumb">
							<li class="breadcrumb-item"><a href="/">Home</a></li>
							<li class="breadcrumb-item"><a href="#">News</a></li>
							<li class="breadcrumb-item active" aria-current="page">{heading}</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
        <div class="site-content">
			<div class="container">
		
				<div class="row">
					 
					<div class="content col-md-8">
		
				 
						<article class="card card--lg post post--single">
		
							
		
							<div class="card__content">
								 
								<header class="post__header">
									<h1 class="post__title">{heading}</h1>
							 
										
									
								</header>
							<figure class="post__thumbnail">
								<img src={image} width="100%" title="dex sports radio" alt=""/>
							</figure>
								<header class="post__header">
										<ul class="post__meta meta mb-20 ">
										<li class="meta__item meta__item--date">
										<time datetime="2017-08-23"> {createdate}</time></li>
									<li class="meta__item meta__item--date"><time datetime="2017-08-23">Author : 
                                      {createdby} </time></li>
									 
									  
									
									</ul>
									 
								
								</header>
 								{/* <div class="post__content">  {contents.replace(regex, "")} </div> */}
							 
								<div class="post__content" dangerouslySetInnerHTML={{__html: contents}} />
		
								{/* <div className="">{myElement}</div>
								<div className="">{root.render(myElement)}</div> */}

		
							</div>
						</article>
					</div>
					<div className="col-md-4">
						<SideBar2></SideBar2>
					</div>
					</div>
					</div>
					</div>
  </>
}

export default BlogDetail