import React, { useEffect, useState } from 'react'
import SideBar from '../../Components/SideBar'


export default function ODI() {
  const [rank, setData] = useState([])
  useEffect(() => {
    fetch(`https://rest.entitysport.com/v2/iccranks?token=ec471071441bb2ac538a0ff901abd249`).then((result) => {
      result.json().then((resp) => {
        setData(resp.response.ranks.bowlers.odis);

      })
    })

  }, [])

  return (
    <>
     <div className="card card--has-table">
      <div className="card__header">
        <h4>ICC ODI's Bowler Ranking</h4>
        {/* <a href="#" className="btn btn-default btn-outline btn-xs card-header__button">See All Stats</a> */}
      </div>
      <div className="card__content">
        <div className="table-responsive">
          <table className="table table-hover table-standings">
            <thead>
              <tr>
              <th>Rank </th>
              <th>Player</th>
              <th>Team</th>
              <th>Rating</th>
            
              <th>Career Best Rating</th>
              </tr>
            </thead>
            <tbody>
                               
                  
 {

rank.map((value, index) => {

              return (

              <tr>
                <td>{value.rank}</td>
                {/* <td>
                  <div className="team-meta">
                    <figure className="team-meta__logo "><img src={value.logo_url} alt="" /></figure>
                    <div className="team-meta__info">
                      <h6 className="team-meta__name ml-2">{value.team}
                        </h6><span className="team-meta__place"></span>
                    </div>
                  </div>
                </td> */}
                       <td>{value.player}</td>
                  <td>{value.team}</td>
                  <td>{value.rating}</td>
           
                  <td>{value.careerbestrating}</td>
               
              </tr>
              );
            })
          }
           
                   
                          
                
                </tbody>
          </table>
        </div>
      </div>
    </div> 
    </>
  )
}
