import React, { useEffect, useState } from "react"
import {
	BrowserRouter as Router,
	generatePath,
	Switch,
	Route,
	useHistory,
	useParams
  } from "react-router-dom";

const UrlNews = () => {
// 	const queryString = window.location.pathname;
// 	console.log('mada', queryString);
//   const [user, setUser] = useState([])
//   const id = 1
const [user, setUser] = useState([])
const [heading, setHeading] = useState([])
const { id } = useParams();

  const fetchData = () => {
	//https://admin.cricplex.com/example/avrnewsdetail/"+data.id
    fetch(`https://admin.cricplex.com/example/avrnewsdetail/${id}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUser(data.data[0].id)
        setUser(data.data[0].heading)
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return <div>Name: {user}</div>
}

export default UrlNews