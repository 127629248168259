import React, { useEffect, useState, Text } from "react";
import { BrowserRouter as Router, useParams} from "react-router-dom";

export default function ScheduleSquad() {


 const { id } = useParams();
 const [data,setData] = useState([])
 
 const [teama,setTeama] = useState([])
 const [teamb,setTeamb] = useState([])
 const [teams0,setteams0] = useState([])
 const [teams1,setteams1] = useState([])
 const [teams,setteams] = useState([])
 const [teama_id,setteama_id] = useState([])
 const [teamb_id,setteamb_id] = useState([])
    
 useEffect(() => {
   fetch(`https://rest.entitysport.com/v2/matches/${id}/squads?token=bf9e12cc4c8f3fbb4c457c9062b84492`).then((result)=>{
     result.json().then((resp)=>{
      // console.warn("result",resp.response.teama);
       setData(resp.response);
  
        setTeama(resp.response.teama.squads);
        setteama_id(resp.response.teama.team_id);
        setteamb_id(resp.response.teamb.team_id);
       setTeamb(resp.response.teamb.squads);
       setteams0(resp.response.teams[0]);
       setteams1(resp.response.teams[1]);
       setteams(resp.response.teams);
      //console.log('teamname', resp.response.teamb.teams[0])
     })
   })

 },[])

// const teamas = data.teama;
  return (
   <>
       <div className="content">
       <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" data-toggle="tab" href="#teama">
                                  {/* {teams.title} */}
                                  {teams0.title}

         


                                  {/* {(function() {
                                           if (value.score =='w') {
                                             return  <span >{value.score}</span>;
                                           }  
                                                      
                                           else {
                                             return <span >{value.score}</span>;
                                           }
                                         })()} */}
                                 </a>
                              </li>
                               <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#teamb">{teams1.title}</a>
                              </li> 
                             {/* <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#menu2">Info</a>
                              </li> */}
                           </ul>
                           <div className="tab-content">
                           <div id="teama" className="tab-pane active">
         <div className="card card--has-table">

          
           <div className="card__content">
             <div className="table-responsive">
               <table className="table table-hover alc-table-stats">
                 <thead>
                   <tr>
                     <th className="alc-player">Player</th>
                     <th className="alc-player">role</th>
                     <th className="alc-player">in</th>
                     <th className="alc-player">out</th>
                     <th className="alc-player">playing11</th>
                   
                      
                   </tr>
                 </thead>
                 <tbody>
                 {teama.map((player,index) => {
               return ( 
                   <tr>
        
                     <td className="alc-name">{player.name} </td>
                     <td className="alc-name">{player.role} </td>
                     <td className="alc-name">{player.in} </td>
                     <td className="alc-name">{player.out} </td>
                     <td className="alc-name">{player.playing11} </td>
                     
                     </tr>
            );
            })
            } 

             
           
                 </tbody>
               </table>
             </div>
           </div>
           </div>
           </div>
           <div id="teamb" className="tab-pane">
         <div className="card card--has-table">

          
           <div className="card__content">
             <div className="table-responsive">
               <table className="table table-hover alc-table-stats">
                 <thead>
                   <tr>
                     <th className="alc-player">Player</th>
                     <th className="alc-player">role</th>
                     <th className="alc-player">in</th>
                     <th className="alc-player">out</th>
                     <th className="alc-player">playing11</th>
                   
                      
                   </tr>
                 </thead>
                 <tbody>
                 {teamb.map((player,index) => {
               return ( 
                   <tr>
        
                     <td className="alc-name">{player.name} </td>
                     <td className="alc-name">{player.role} </td>
                     <td className="alc-name">{player.in} </td>
                     <td className="alc-name">{player.out} </td>
                     <td className="alc-name">{player.playing11} </td>
                     
                     </tr>
            );
            })
            } 

             
           
                 </tbody>
               </table>
             </div>
           </div>
           </div>
           </div>
           </div>
 
     
</div>
</>
 )
}
