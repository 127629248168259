import React, { useEffect, useState, Text } from "react";
import { BrowserRouter as Router, useParams} from "react-router-dom";

export default function Scorecard() {


 const { id } = useParams();
 const [data,setData] = useState([])
 const [batsmen,setBatsmen] = useState([0,1])
 const [bowlers,setBowlers] = useState([0,1])
 const [innings,setinnings] = useState([])
 const [commentaries,setcommentaries] = useState([])
  const [equations,setequations] = useState([])
 const [teama,setTeama] = useState([])
 const [teamb,setTeamb] = useState([])
 const [competition,Setcompetition] = useState([])
   
 useEffect(() => {
   fetch(`https://rest.entitysport.com/v2/matches/${id}/scorecard?token=bf9e12cc4c8f3fbb4c457c9062b84492`).then((result)=>{
     result.json().then((resp)=>{
      // console.warn("result",resp.response.teama);
       setData(resp.response);
  
       Setcompetition(resp.response.competition);
       setTeama(resp.response.teama);
       setTeamb(resp.response.teamb);
       setinnings(resp.response.innings);
   
     })
   })

 },[])

 // const teamas = data.teama;
 console.warn("result",innings);
 return (
   <>
       <div className="content">
       {innings.slice(0, 3).map((value,index) => {
               return (   
                <>
         <div className="card card--has-table">

           <div className="card__header card__header--has-team-info">
             <div className="team-meta">
              
               <div className="team-meta__info">
                 <h6 className="team-meta__name">{value.short_name}</h6>
                 <span className="team-meta__place">{value.name}</span>
               </div>
             </div>
             <button type="button" class="btn btn-warning btn-outline btn-sm alert-btn-right">{value.scores_full}</button>
           </div>
           <div className="card__content">
             <div className="table-responsive">
               <table className="table table-hover alc-table-stats">
                 <thead>
                   <tr>
                     <th className="alc-player">Batsmen</th>
                     <th className="alc-position"></th>
                     <th className="alc-min">R</th>
                     <th className="alc-fgm-a">B</th>
                     <th className="alc-fgpercent">4S</th>
                     <th className="alc-threepm-a">6S</th>
                     <th className="alc-threepercent">SR</th>
                      
                   </tr>
                 </thead>
                 <tbody>
                 {value.batsmen.map((batsmen,index) => {
               return ( 
                   <tr>
                    
                     {batsmen.how_out =='Not out' ? (
        <> 
         <td className="alc-name text-success"><b>{batsmen.name} {batsmen.role_str}</b></td>
         </>
      ) : (
      <> <td className="alc-name text-danger">{batsmen.name} {batsmen.role_str}</td></>
      )}


{batsmen.how_out =='Not out' ? (
        <> 
         <td className="alc-position text-success">{batsmen.how_out}</td>
         </>
      ) : (
      <> <td className="alc-name  text-danger">{batsmen.how_out} </td></>
      )}


                   
                     <td width={10}>{batsmen.runs} </td>
                     <td width={10}>{batsmen.balls_faced} </td>
                     <td width={10}>{batsmen.fours} </td>
                     <td width={10}>{batsmen.sixes} </td>
                     <td width={10}>{batsmen.strike_rate} </td>
                     </tr>
            );
            })
            } 

            <tr>
             <td colSpan={3} className="text-left">Extras</td>
             <td className="text-right" colSpan={4}>{value.extra_runs.total}  (B {value.extra_runs.byes}, w {value.extra_runs.wides}, nb {value.extra_runs.noballs}, lb {value.extra_runs.legbyes})</td>
            </tr>
            <tr>
             <td colSpan={3} className="text-left">Total</td>
             <td className="text-right" colSpan={4}>{value.scores_full}</td>
            </tr>
                 </tbody>
               </table>
             </div>
           </div>
           </div>
 
       <div className="card card--has-table">
           <div className="card__content">
             <div className="table-responsive">
               <table className="table table-hover alc-table-stats">
                 <thead>
                   <tr>
                     <th className="alc-player">Bowler</th>
                      <th className="alc-min">O</th>
                     <th className="alc-fgm-a">M</th>
                     <th className="alc-fgpercent">R</th>
                     <th className="alc-threepm-a">W</th>
                     <th className="alc-threepercent">ECON</th>
                      
                   </tr>
                 </thead>
                 <tbody>
                 {value.bowlers.map((bowlers,index) => {
               return ( 
                   <tr>
                     <td className="alc-name">{bowlers.name} {bowlers.role_str} </td>
                      <td width={10}>{bowlers.overs} </td>
                     <td width={10}>{bowlers.maidens} </td>
                     <td width={10}>{bowlers.runs_conceded} </td>
                     <td width={10}>{bowlers.wickets} </td>
                     <td width={10}>{bowlers.econ} </td>
                    
                    
                     
                   </tr>
            

            );    })      } 


            <tr>
             <td colSpan={6} className="text-left">  Fall of wickets:   {value.fows.map((fows,index) => {
               return ( <>
               <strong>{fows.number}-{fows.score_at_dismissal} </strong><span>({fows.name} - {fows.overs_at_dismissal}), </span>
               </>
                );    })      } 
               
               </td>
            
            </tr>
               
                 </tbody>
               </table>
              
             </div>
           </div>


               
         

           

         </div>
         </>
         );
             
       })
     } 
</div>
</>
 )
}
