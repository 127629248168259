import React, { useEffect, useState, Text } from "react";
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import HomeTabsNews from "./HomeTabsNews";
import SideBar from "./SideBar";
import IplNews from "./IplNews";
import HomePointsTable from "../Matches/HomePointsTable";
import HomeVideos from "../Videos/HomeVideos";
import HomeVideos_feature from "../Videos/HomeVideos_feature";
import MultiVideoHome from "../Videos/MultiVideoHome";
import T20 from "../Ranking/Team/T20";
import HomeRankTable from "../Matches/HomeRankTable";
import HomeRadio from "./HomeRadio";
import HomeSingleLiveMatch from "../Matches/HomeSingleLiveMatch";

export default function HomeNews() {
    
   

  // const [news,setData] = useState([])
  // useEffect(() => {
  //   fetch(`https://admin.cricplex.com/mobileapi/top10news`).then((result)=>{
  //     result.json().then((resp)=>{
  //        // console.log(resp.news)
  //       setData(resp.news.slice(0, 6));
      
  //     })
  //   })

  // },[])
         
            
  
    return (
    <>


       <div class="site-content p-3">
				<div class="container">
				 
                    <div className="row">
                    <div className="col-md-8">
                    <img src={"/assets/images/ads/3.jpg"} width="100%"></img>
                    <br></br>   <br></br>
                    <IplNews></IplNews>
                    </div>
                    <div id="sidebar" class="sidebar col-md-4">
                      {/* <HomePointsTable></HomePointsTable> */}
                      {/* <HomeRadio></HomeRadio> */}
                    <HomeRankTable></HomeRankTable>
			          <SideBar></SideBar>  
						</div>
                    </div>
					</div> 
					</div>
       
    </>
  )
}

