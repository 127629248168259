import React from 'react'
import SideBar from './SideBar'
import { Helmet } from 'react-helmet';


export default function LiveRadio() {
  return (
    <>
	<Helmet>
        <title>Live Radio Commentary | Cricket Radio Listen | Cricket Fact, Kahi ansuni kahaniya, cricket update</title>
        <meta name="description" content="Listen live cricket commentary with DexSportsRadio.com ,  Get Live Cricket Score, Scorecard, Schedules of  International or Domestic sports matches along with Latest News with Dex Sports Radio" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="Cricplex | Live Radio Commentary, Score, Schedule, Latest News" />
        <meta property="og:description" content="Listen live cricket commentary with DexSportsRadio.com ,  Get Live Cricket Score, Scorecard, Schedules of  International or Domestic sports matches along with Latest News with Dex Sports Radio" />
        <meta property="og:image" content="https://www.cricplex.com/assets/images/cricplex.png" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="Cricplex | Live Radio Commentary, Score, Schedule, Latest News" />
        <meta name="twitter:description" content="Listen live cricket commentary with DexSportsRadio.com ,  Get Live Cricket Score, Scorecard, Schedules of  International or Domestic sports matches along with Latest News with Dex Sports Radio" />
        <meta name="twitter:image" content="https://www.cricplex.com/assets/images/cricplex.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* <div class="page-heading">
			<div class="container">
				<div class="row">
					<div class="col-md-10 offset-md-1">
						<h1 class="page-heading__title">Cricplex <span class="highlight">Radio</span></h1>
						<ol class="page-heading__breadcrumb breadcrumb">
							<li class="breadcrumb-item"><a href="/">Home</a></li>
							<li class="breadcrumb-item"><a href="#">Live Radio</a></li>
 						</ol>
					</div>
				</div>
			</div>
		</div> */}
        <div class="site-content">
			<div class="container">
		
				<div class="row">
					 
					<div class="content col-md-8">
		
				 
						<article class="card card--lg post post--single">
                        <iframe     width="100%" height="500" src="https://player.onestream.live/embed?token=NTY1ODM=&type=up" allow="autoplay" scrolling="no" frameborder="0"   allowfullscreen></iframe>
                       
					   
					   
					    <div className="live-video-description">
        <div className="container">
          <p> Missing out on big scores, bored with your office chores, unplug the live cricket radio on Cricplex Radio, and enjoy the thrill of cricket commentary mixed with Bollywood tunes. Cricplex Radio unfolds the amazing entertainment and fun of live cricket commentary for all our cricket fanatics. Our live cricket radio application covers each and every headline of the game, be it the spectacular match moments or delightful cricket stories or new era of hip-hop beats, Cricplex Radio will make you enjoy cricket in the most relishing manner. All the live matches happening anywhere around the world are covered by our expert commentators, providing all the cricket enthusiasts with the feature of ball-to-ball details. Download our cricket radio app and dance to the tunes of our radio while getting all the live cricket scores</p>
        </div>
      </div>
						</article>
                        

					</div>
                    <div id="sidebar" className="sidebar col-md-4">
<SideBar></SideBar>
   </div>
					</div>
					</div>
					</div>
    </>
  )
}
