import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SideBar from "../Components/SideBar";

export default function RecentFootballMatch() { 
    const [news, setNews] = useState([])
     
    const getAnswer = async () => {
        axios.get("https://soccer.entitysport.com/matches?token=44689d60663efa7ad59e4903675b794e")
        .then((response) => {
        //  console.log(response);
        setNews(response.data.response.items)
        })
    };
    useEffect(() => {
               getAnswer();
            });
  

    const navigate = useNavigate();
    const handleClick = (data) => {
      axios.get("https://rest.entitysport.com/v2/matches/"+data.match_id+"/live?token=97e19253b3ceb33edc4ec694576c840b")
        .then((response) => {
              navigate('/live-score',{
                  state: response.data.response
       });
           
          })
     
    }

  
  return (
    <>
     <div class="page-heading">   
   <div class="container">
      <div class="row">
         <div class="col-md-10 offset-md-1">
            <h1 class="page-heading__title">Live <span class="highlight"> Football Matches</span></h1>
            <ol class="page-heading__breadcrumb breadcrumb">
               <li class="breadcrumb-item"><a href="/">Home</a></li>
               <li class="breadcrumb-item active" aria-current="page">Recent Football Matches</li>
            </ol>
         </div>
      </div>
   </div>
</div>
<div class="site-content">
   <div class="container">
      <div class="row">
        			
         <div class="content col-md-8">
      					
            <div class="card">
               <header class="card__header card__header--has-btn">
                  <h4>Recent Football Matches </h4>
               </header>
               </div>
             

               {
            
            news.map((value,index) => {
              
              return (
                <>
                 <div class="card">
                <div class="card__content">
                
                <div class="game-result">
                   <section class="game-result__section">
                      <header class="game-result__header game-result__header--alt mb-0 pb-0">
                         <span class="game-result__league">{value.competition.cname}</span>
                         <h3 class="game-result__title">{value.venue.name}, {value.venue.location}</h3>
                         <time class="game-result__date" datetime="2017-03-17">{value.datestart}</time>
                      </header>
                      <div class="game-result__content mb-0">
                       
                         <div class="game-result__team game-result__team--first">
                            <figure class="game-result__team-logo"><img src={value.teams.home.logo} alt=""/></figure>
                            <div class="game-result__team-info">
                               <h5 class="game-result__team-name">{value.teams.home.tname}</h5>
                               <div class="game-result__team-desc">{value.teams.home.fullname}</div>
                            </div>
                         </div>
                         
                         <div class="game-result__score-wrap">
                            <div class="game-result__score game-result__score--lg"><span class="game-result__score-result game-result__score-result--winner">{value.result.home}</span> <span class="game-result__score-dash">-</span> <span class="game-result__score-result game-result__score-result--loser">{value.result.away}</span></div>
                            <div class="game-result__score-label">Final Score</div>
                         </div>
                       
                         <div class="game-result__team game-result__team--second">
                            <figure class="game-result__team-logo"><img src={value.teams.away.logo} alt=""/></figure>
                            <div class="game-result__team-info">
                               <h5 class="game-result__team-name">{value.teams.away.tname}</h5>
                               <div class="game-result__team-desc">{value.teams.away.fullname}</div>
                            </div>
                         </div>
                        
                      </div>
                   </section>
                   
                </div>
               
             </div>
             </div>
             <br></br></>
);
})
} 

 


</div>
<div id="sidebar" className="sidebar col-md-4">
   <SideBar></SideBar>
   </div>


</div></div></div>
        
    </>
  )
}
