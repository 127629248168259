import React, {useEffect, Fragment, useState} from 'react';
import {
	BrowserRouter as Router,
	generatePath,
	Switch,
	Route,
	useHistory,
	useParams
  } from "react-router-dom";
  import SideBar from "./SideBar";


import {Link} from "react-router-dom";
export default function IplNews() {
	const { id } = useParams();
    const [video,setData] = useState([])
    useEffect(() => {
      fetch(`https://admin.cricplex.com/Mobileapi/video_category_wise_video/${id}`).then((result)=>{
        result.json().then((resp)=>{
            console.log(resp.vedio)
        //  setData(resp.news.slice(0, 5));
          setData(resp.vedio);
        
        })
      })
  
    },[])

  return (
    <>
	{/* <div class="page-heading">   
   <div class="container">
      <div class="row">
         <div class="col-md-10 offset-md-1">
            <h1 class="page-heading__title">Latest <span class="highlight"> Sports News</span></h1>
            <ol class="page-heading__breadcrumb breadcrumb">
               <li class="breadcrumb-item"><a href="/">Home</a></li>
               <li class="breadcrumb-item active" aria-current="page">Latest Updates</li>
            </ol>
         </div>
      </div>
   </div>
</div> */}
       <div class="site-content">
				<div class="container">
				<div class="row">
				<div class="content col-md-8">
                   
				<div class="card card--clean">
				<div class="gallery row">
                  {
                  video.length ?(video.map((value,index) => {
                   return (
<div class="gallery__item col-6 col-sm-6">
	<a href={"/watch-video/"+ value.id} class="gallery__item-inner card">

		<figure class="gallery__thumb">
			<img width="100%" height="270" src={value.image_url} class="attachment-alchemists_thumbnail size-alchemists_thumbnail wp-post-image" alt="" decoding="async" loading="lazy"/>			<span class="btn-fab gallery__btn-fab"></span>
		</figure>

		<div class="gallery__content card__content">
			<span class="gallery__icon">
				<span class="icon-camera" ><img src="https://img.icons8.com/?size=100&id=V6jsd74jR29Q&format=png&color=000000" width="40" /></span>
			</span>
			<div class="gallery__details">
				<h4 class="">{value.heading} </h4>
				<div class="gallery__date">{value.createdate}</div>
			</div>
		</div>
	</a>
</div>
					  

               );
                
            })
        ) : (
          <p className='text-white'>No Live Match</p>
        ) } 

					</div>
					 
					</div>
					</div> 
                    <div id="sidebar" class="sidebar col-md-4">
			 
					
					 
					  
                        <SideBar></SideBar>  
						</div>

					</div> 
					</div> 
					</div>
	</>
   )
}
