import React, { useEffect, useState } from "react"
import {Link} from "react-router-dom";
import SideBar from "./SideBar";

import {
	BrowserRouter as Router,
	useParams
  } from "react-router-dom";

export default function CategoryNews() {
    const { id } = useParams();
    const [news,setData] = useState([])
    useEffect(() => {
      fetch(`https://admin.cricplex.com/mobileapi/category_wise_news/${id}`).then((result)=>{
        result.json().then((resp)=>{
         //   console.log(resp.news)
          setData(resp.news);
        
        })
      })
  
    },[])

  return (
    <div>
      {/* <div class="page-heading">   
    <div class="container">
       <div class="row">
          <div class="col-md-10 offset-md-1">
             <h1 class="page-heading__title"> <span class="highlight">   {
                 news.slice(0, 1).map((value,index) => {
                 return ( <> {value.cat_name} </>
                    );
                    })
                    } 
                    </span></h1>
             <ol class="page-heading__breadcrumb breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Latest Updates</li>
             </ol>
          </div>
       </div>
    </div>
 </div> */}
        <div class="site-content">
                 <div class="container">
                 <div class="row">
                 <div class="content col-md-8">
                    
                 <div class="card card--clean">
                 <div class="posts posts--cards post-grid row">
              
                 {
                 news.map((value,index) => {
                 return (
 
                 <div class="post-grid__item col-sm-6">
                 <div   class="posts__item posts__item--card posts__item--category-1 card" title="title">
                 <figure class="posts__thumb">
                 <div class="posts__cat">
                  <span class="label posts__cat-label">{value.cat_name}</span>
                 </div>
                 <Link to={"/news-detail/"+ value.id}>
                     <img src={value.image} alt="" />
                 </Link>
                  </figure>
                  <div class="posts__inner card__content">
                  <Link to={"/news-detail/"+ value.id} class="posts__cta"></Link>
                  <time datetime="2016-08-23" class="posts__date">{value.createdate}</time>
                 <h3 class="posts__title"> {value.heading}  </h3>
                 <div class="posts__excerpt" > 	{value.summery} ...
                 <Link to={"/news-detail/"+ value.id}>Read More</Link>											
                     </div>
                     </div>
                     </div>
                     </div> 
 
                     );
                     })
                     } 
 
                     </div>
                      
                     </div>
                     </div> 
                     <div id="sidebar" class="sidebar col-md-4">
              
                     
                      
                       
                         <SideBar></SideBar>  
                         </div>
 
                     </div> 
                     </div> 
                     </div></div>
  )
}
