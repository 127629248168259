import React, { Component, useEffect, useState, Text } from 'react'

import { BrowserRouter as Router, useParams} from "react-router-dom";

import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import axios from 'axios'
import moment from "moment";    
import SideBar from '../../Components/SideBar';
import SeriesLink from './SeriesLink';


export default function Series() {
  const { id } = useParams();
  const { status } = useParams();

    const [series, setSeries] = useState([])
    
    const getAnswer = async () => {
    axios.get('https://rest.entitysport.com/v2/competitions?token=bf9e12cc4c8f3fbb4c457c9062b84492&per_page=100&&paged=1&status='+status+'')
      .then((response) => {
        //console.log(response);
        setSeries(response.data.response.items)
      }) 
    };

    
  
        useEffect(() => {
          getAnswer();
       
       
       },[]);
  
  return (
    <>


<nav class="content-filter">
        <div class="container">
          <a href="#" class="content-filter__toggle"></a>
          <ul class="content-filter__list">

          {(function() {
                                           if (status =='live') {
                                             return   <> <li class="content-filter__item  content-filter__item--active" >
                                             <a href="/series/live" class="content-filter__link">
                                             Current  <small>Series</small>
                                             </a>
                                           </li>
                                           <li class="content-filter__item">
                                             <a href="/series/fixture" class="content-filter__link">
                                              Upcoming <small>Series</small>
                                             </a>
                                           </li>
                                           <li class="content-filter__item">
                                             <a href="/series/result" class="content-filter__link">
                                               Completed <small>Series</small>
                                             </a>
                                           </li></>;
                                           }   if (status =='fixture') {
                                            return   <> <li class="content-filter__item  " >
                                            <a href="/series/live" class="content-filter__link">
                                            Current  <small>Series</small>
                                            </a>
                                          </li>
                                          <li class="content-filter__item content-filter__item--active">
                                            <a href="/series/fixture" class="content-filter__link  ">
                                             Upcoming <small>Series</small>
                                            </a>
                                          </li>
                                          <li class="content-filter__item">
                                            <a href="/series/result" class="content-filter__link">
                                              Completed <small>Series</small>
                                            </a>
                                          </li></>;
                                          }   if (status =='result') {
                                            return   <> <li class="content-filter__item  " >
                                            <a href="/series/live" class="content-filter__link">
                                            Current  <small>Series</small>
                                            </a>
                                          </li>
                                          <li class="content-filter__item">
                                            <a href="/series/fixture" class="content-filter__link">
                                             Upcoming <small>Series</small>
                                            </a>
                                          </li>
                                          <li class="content-filter__item content-filter__item--active">
                                            <a href="/series/result" class="content-filter__link ">
                                              Completed <small>Series</small>
                                            </a>
                                          </li></>;
                                           }    
                                                      
                                           
                                         })()}

           
         
          </ul>
        </div>
      </nav>

     
{/* <SeriesLink></SeriesLink> */}
    <div class="site-content">
  
				<div class="container">
				<div class="row">
				<div class="content col-md-8">

        <div id="primary" class="content-area">
			<main id="main" class="site-main">
				<div class="sp-template sp-template-event-list card card--has-table">
			<header class="card__header">
			<h4 class="sp-table-caption">      {(function() {
                                           if (status =='live') {
                                             return   <>Current</>;
                                           }   if (status =='fixture') {
                                            return   <>Upcoming</>;
                                          }   if (status =='result') {
                                            return   <>Completed</>;
                                           }    
                                                      
                                           
                                         })()} Series</h4>
					</header>

          <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" data-toggle="tab" href="#International">International</a>
                              </li>
                               <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#domestic">Domestic & Other</a>
                              </li>
                               
                               <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#women">Women</a>
                              </li>
                            
                           </ul>

                           <div className="tab-content">
                           <div id="International" className="tab-pane active">
                           <table class="table table-hover   team-schedule--full sp-event-list sp-event-list-format-title sp-data-table sp-paginated-table sp-sortable-table sp-scrollable-table dataTable no-footer" data-sp-rows="10" id="DataTables_Table_0" role="grid">
				<thead>
					<tr role="row">
            <th class="data-date sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Date">Date</th>
            <th class="data-event sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Game">Match Details</th>
            {/* <th class="data-time sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Time"> Matches</th>
            <th class="data-time sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Time"> Teams</th>
           */}
            
            </tr>
				</thead>
				<tbody>
        {
                                    series.map((value,index) => {
                                     
                                      if(value.category === "international"){
                                         return (
									<tr class="sp-row sp-post alternate sp-row-no-0 odd"   role="row">
                  <td class="data-date" itemprop="startDate" content="" data-label="Date">
                    <a href={"/series-matches/"+ value.cid}  itemprop="url"><date>{value.datestart} - {value.dateend}</date> </a></td>
                  <td class="data-event" data-label="Game">
                  <a href={"/series-matches/"+ value.cid} className='text-body'  itemprop="url">
                      <span> {value.title}, {value.abbr}, {value.abbr}</span>
                      </a>
                       </td>
                  </tr>
                  
                )} ;
              })
              }   
                  </tbody>
			</table>
                            </div>
                            <div id="domestic" className="tab-pane ">
                            <table class="table table-hover   team-schedule--full sp-event-list sp-event-list-format-title sp-data-table sp-paginated-table sp-sortable-table sp-scrollable-table dataTable no-footer" data-sp-rows="10" id="DataTables_Table_0" role="grid">
				<thead>
					<tr role="row">
            <th class="data-date sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Date">Date</th>
            <th class="data-event sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Game">Match Details</th>
            {/* <th class="data-time sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Time"> Matches</th>
            <th class="data-time sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Time"> Teams</th>
           */}
            
            </tr>
				</thead>
				<tbody>
        {
                                    series.map((value,index) => {
                                      if(value.category === "domestic"){
                                    
                                         return (
                                          <tr class="sp-row sp-post alternate sp-row-no-0 odd"   role="row">
                                          <td class="data-date" itemprop="startDate" content="" data-label="Date">
                                            <a href={"/series-matches/"+ value.cid}  itemprop="url"><date>{value.datestart} - {value.dateend}</date> </a></td>
                                          <td class="data-event" data-label="Game">
                                          <a href={"/series-matches/"+ value.cid} className='text-body'  itemprop="url">
                                              <span> {value.title}, {value.abbr}, {value.abbr}</span>
                                              </a>
                                               </td>
                                          </tr>
                  
                )};
              })
              }   
                  </tbody>
			</table>
                            </div>
                            
                            <div id="women" className="tab-pane ">
                            <table class="table table-hover   team-schedule--full sp-event-list sp-event-list-format-title sp-data-table sp-paginated-table sp-sortable-table sp-scrollable-table dataTable no-footer" data-sp-rows="10" id="DataTables_Table_0" role="grid">
				<thead>
					<tr role="row">
            <th class="data-date sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Date">Date</th>
            <th class="data-event sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Game">Match Details</th>
            {/* <th class="data-time sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Time"> Matches</th>
            <th class="data-time sorting" tabindex="0" aria-controls="DataTables_Table_0" aria-label="Time"> Teams</th>
           */}
            
            </tr>
				</thead>
				<tbody>
        {
                                    series.map((value,index) => {
                                      if(value.category === "women"){
                                         return (
                                          <tr class="sp-row sp-post alternate sp-row-no-0 odd"   role="row">
                                          <td class="data-date" itemprop="startDate" content="" data-label="Date">
                                            <a href={"/series-matches/"+ value.cid}  itemprop="url"><date>{value.datestart} - {value.dateend}</date> </a></td>
                                          <td class="data-event" data-label="Game">
                                          <a href={"/series-matches/"+ value.cid} className='text-body'  itemprop="url">
                                              <span> {value.title}, {value.abbr}, {value.abbr}</span>
                                              </a>
                                               </td>
                                          </tr>
                  
                )};
              })
              }   
                  </tbody>
			</table>
                            </div>
                            </div>

	 
</div>

			</main>
		</div>


			 
           </div> 
                     <div id="sidebar" class="sidebar col-md-4">
        
           
            <SideBar></SideBar>
             
                          
             </div>
 
           </div> 
           </div> 
           </div>

    <div class="container">  
    <div class="site-content">
		
    
			
</div>
</div>
 
    </>
  )
}
