import React, { useEffect, useState } from "react"
import {
	BrowserRouter as Router,
	generatePath,
	Switch,
	Route,
	useHistory,
	useParams
  } from "react-router-dom";
import SideBar2 from "./Sidebar2";

const Watch_Video = () => {
// 	const queryString = window.location.pathname;
// 	console.log('mada', queryString);
//   const [user, setUser] = useState([])
//   const id = 1
const [newsid, setid] = useState([])
 const [heading, setHeading] = useState([])
  const [category, setcategory] = useState([])
 const [summery, setsummery] = useState([])
 const [content, setcontent] = useState([])
 const [createdate, setcreatedate] = useState([])
 const [url, seturl] = useState([])
 const [image, setimage] = useState([])
const { id } = useParams();

  const fetchData = () => {
	//https://admin.cricplex.com/example/avrnewsdetail/"+data.id
    fetch(`https://admin.cricplex.com/Mobileapi/video_detail/${id}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
       // setid(data.id)
        setHeading(data.title)
        setcategory(data.catname)
         setcreatedate(data.createdate)
        seturl(data.url)
        setimage(data.image_url)
      })
  }
  const regex = /(<([^>]+)>)/gi;
  useEffect(() => {
    fetchData()

  }, [])

  

  
var contents = content.toString();
 //console.log(heading);
 
  return <>
  {/* <div class="page-heading">
			<div class="container">
				<div class="row">
					<div class="col-md-10 offset-md-1">
						<h1 class="page-heading__title"><span class="highlight">{heading}</span></h1>
						<ol class="page-heading__breadcrumb breadcrumb">
							<li class="breadcrumb-item"><a href="/">Home</a></li>
							<li class="breadcrumb-item"><a href="#">News</a></li>
							<li class="breadcrumb-item active" aria-current="page">{category}</li>
						</ol>
					</div>
				</div>
			</div>
		</div> */}
        <div class="site-content">
			<div class="container">
		
				<div class="row">
					 
					<div class="content col-md-8">
		
				 
						<article class="card card--lg post post--single">
		
							
		
							<div class="card__content">
								<div class="post__category">
									<span class="label posts__cat-label">{category}</span>
								</div>
								
							<figure class="post__thumbnail">
							<iframe width="100%" height="400"  src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
							</figure>
						 
								<header class="post__header">
								<h1 class="post__title">{heading}</h1>
										<ul class="post__meta meta mb-20 ">
										<li class="meta__item meta__item--date">
										<time datetime="2017-08-23"> {createdate}</time></li>
									 
									
								 
									
									</ul>
									 
								
								</header>
                                <h5>{summery}</h5>
								{/* <div class="post__content">  {contents.replace(regex, "")} </div> */}
							 
								<div class="post__content" dangerouslySetInnerHTML={{__html: contents}} />
		
								
		
							</div>
						</article>
					</div>
					<div className="col-md-4">
						<SideBar2></SideBar2>
					</div>
					</div>
					</div>
					</div>
  </>
}

export default Watch_Video