//import ReactDOM from "react-dom";
import React from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from './Components/Home'
import IplNews from "./Components/IplNews";
import LiveMatches from "./Matches/LiveMatches";
import Scorecard from "./Matches/Scorecard";
import RecentMatches from "./Matches/RecentMatches";
import ScheduleMatches from "./Matches/ScheduleMatches";
import NewsDetail from "./Components/NewsDetail";
import MatchDetail from "./Matches/MatchDetail";
import All_Ajmancup from "./Components/All_Ajmancup";
import All_Iplnews from "./Components/All_Iplnews";
import LiveRadio from './Components/LiveRadio';
import RecentFootballMatch from './Football/RecentFootballMatch';
import News from './Components/News';
import UrlNews from './Components/UrlNews';
import CategoryNews from './Components/CategoryNews';
import Test from './Components/Test';
import Test2 from './Matches/Test';
import PlayerProfile from './Components/Paralympics/PlayerProfile';
import Paralympics from './Components/Paralympics/Paralympics';
import MultiCarousal from './Components/MultiCarousal';

import T20 from './Ranking/Team/T20';
import ODI from './Ranking/Team/ODI';
import Tests from './Ranking/Team/Tests';
import batT20 from './Ranking/Batsmen/BatsMenT20';
import BatsMenT20 from './Ranking/Batsmen/BatsMenT20';
import BatsMenODI from './Ranking/Batsmen/BatsMenODI';
import BatsMenTests from './Ranking/Batsmen/BatsMenTests';

import BowlerT20 from './Ranking/Bowler/BowlerT20';
import BowlerODI from './Ranking/Bowler/BowlerODI';
import BowlerTests from './Ranking/Bowler/BowlerTests';

import AllrounderT20 from './Ranking/Allrounder/AllrounderT20';
import AllrounderODI from './Ranking/Allrounder/AllrounderODI';
import AllrounderTests from './Ranking/Allrounder/AllrounderTests';
import Video_LIst_Category_Wise from './Components/Video_LIst_Category_Wise';
import Watch_Video from './Components/Watch_Video';
import LiveScore from './Matches/LiveScore';
import TeamTabs from './Ranking/Team/TeamTabs';
import BowlerTabs from './Ranking/Bowler/BowlerTabs';
import BatsMenTabs from './Ranking/Batsmen/BatsMenTabs';
import ScheduleDetail from './Matches/ScheduleDetail';
import Blog from './Components/Blog';
import BlogDetail from './Components/BlogDetail';
import ComptitionSchedule from './Matches/ComptitionSchedule';
import HomePointsTable from './Matches/HomePointsTable';
import Series from './Matches/Series/Series';
import SeriesMatches from './Matches/Series/SeriesMatches';
import TermsCondition from './Components/TermsCondition';
import PrivacyPolicy from './Components/PrivacyPolicy';
function App() {

   
  return (
   <> 
   <BrowserRouter>
   <Header></Header>
      <Routes>
     

        <Route path="/" element={<Home />}></Route>
        <Route path="/ipl-2022-news" element={<IplNews />}></Route>
        <Route path="/latest-news" element={<News />}></Route>
        <Route path="/url-news/:id" element={<UrlNews />}></Route>
      
        <Route path="/news-detail/:id" element={<NewsDetail/>} ></Route>
        <Route path="/ajman-cup-news-2022" element={<All_Ajmancup/>} ></Route>
        <Route path="/ipl-news-2022" element={<All_Iplnews/>} ></Route>
        <Route path="/live-radio" element={<LiveRadio/>} ></Route>

        <Route path="/livematches" element={<LiveMatches />}></Route>
        <Route path="/recent-matches" element={<RecentMatches />}></Route>
        <Route path="/schedule-matches" element={<ScheduleMatches />}></Route>
        <Route path="/live-score" element={<MatchDetail/>} ></Route>
        <Route path="/live-cricket-scorecard/:id" element={<Scorecard/>} ></Route>
        <Route path="/live-cricket-score/:id" element={<LiveScore/>} ></Route>
        <Route path="/match-Schedule/:id" element={<ScheduleDetail/>} ></Route>

        <Route path="/recent-football-matches" element={<RecentFootballMatch/>} ></Route>
        <Route path="/test" element={<Test/>} ></Route>
        <Route path="/test2" element={<Test2/>} ></Route>

        <Route path="/category-news/:id" element={<CategoryNews/>} ></Route>
        <Route path="/paralympics/player-profile" element={<PlayerProfile />}></Route>
        <Route path="/paralympics" element={<Paralympics />}></Route>
        <Route path="/multicarousal" element={<MultiCarousal />}></Route>




        <Route path="icc-teams-t20-ranking" element={<T20/>} ></Route>
        <Route path="icc-teams-odi-ranking" element={<ODI/>} ></Route>
        <Route path="icc-teams-test-ranking" element={<Tests/>} ></Route>
 
        <Route path="icc-batsmen-t20-ranking" element={<BatsMenT20/>} ></Route>
        <Route path="icc-batsmen-odi-ranking" element={<BatsMenODI/>} ></Route>
        <Route path="icc-batsmen-tests-ranking" element={<BatsMenTests/>} ></Route>
       
        <Route path="icc-bowler-t20-ranking" element={<BowlerT20/>} ></Route>
        <Route path="icc-bowler-odi-ranking" element={<BowlerODI/>} ></Route>
        <Route path="icc-bowler-tests-ranking" element={<BowlerTests/>} ></Route>

        <Route path="icc-allrounder-t20-ranking" element={<AllrounderT20/>} ></Route>
        <Route path="icc-allrounder-odi-ranking" element={<AllrounderODI/>} ></Route>
        <Route path="icc-allrounder-tests-ranking" element={<AllrounderTests/>} ></Route>
        
        <Route path="/video-category/:id" element={<Video_LIst_Category_Wise/>} ></Route>
        <Route path="/watch-video/:id" element={<Watch_Video/>} ></Route>

        <Route path="icc-teams-ranking" element={<TeamTabs/>} ></Route>
        <Route path="icc-bowler-ranking" element={<BowlerTabs/>} ></Route>
        <Route path="icc-batsmen-ranking" element={<BatsMenTabs/>} ></Route>
        <Route path="blog" element={<Blog/>} ></Route>
        <Route path="/blog-detail/:id" element={<BlogDetail/>} ></Route>
        <Route path="/comptition-schedule/:id" element={<ComptitionSchedule/>} ></Route>
        <Route path="/points-table/:id" element={<HomePointsTable/>} ></Route>
        <Route path="/series/:status" element={<Series/>} ></Route>

        <Route path="/series-matches/:id" element={<SeriesMatches/>} ></Route>

        <Route path="terms-and-conditions" element={<TermsCondition/>} ></Route>
        <Route path="privacy-policy" element={<PrivacyPolicy/>} ></Route>

      </Routes>
      <Footer></Footer> 
    </BrowserRouter>
    </>
  )
}

export default App;