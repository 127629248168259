import React, { Component, useEffect, useState, Text } from 'react'
import { BrowserRouter as Router, useParams} from "react-router-dom";

import axios from 'axios'
import SideBar from '../Components/SideBar';
import Scorecard from './Scorecard';
import ScoreTopSection from './ScoreTopSection';
import ScheduleSquad from './ScheduleSquad';

export default function ScheduleDetail() {

  const { id } = useParams();

  const [match, SetMatch] = useState([]);
  const [teama,setTeama] = useState([]);
  const [teamb,setTeamb] = useState([]);
  const [competition,setcompetition] = useState([]);
  const [venue,setvenue] = useState([]);
  const [toss,settoss] = useState([]);
  
const [items, setItems] = useState([{}]);
 
// console.log(batsmen1);
useEffect(() => {
    axios.get('https://rest.entitysport.com/v2/matches/'+id+'/info?token=bf9e12cc4c8f3fbb4c457c9062b84492').then(response => {
      SetMatch(response.data.response);
       setTeama(response.data.response.teama);
      setTeamb(response.data.response.teamb);
      setcompetition(response.data.response.competition);
      setvenue(response.data.response.venue);
      settoss(response.data.response.toss);
     
    
    }).catch(err => console.log(err));
}, [])

 

 

  return (
    <div>
     
     
    
       <section className="site-content" id={match.match_id}>
       <div className="container">
        <div className='row'>
        <div className='content col-md-8'>
                     <div className="card bg-white mb-20 p-20">
                        <div className="card-body">
                        
                        <div>   <div className='score_top_section'>
    <h6 className="text-center font-weight-bold "> {competition.title}, {match.subtitle}, {match.format_str}
       </h6>
      
     <div className="row">
          <div className="col-lg-4 col-sm-4 col-md-4 col-12">
<img style={{width:"60px"}} className="img-fluid float-left align-items-center w-20 mr-3"  src={teama.logo_url}  alt="team logo"  />
              <div className='float-left '><b>
             <span className="">{teama.name}</span>
             {/* <p className=" ">{teama.scores_full}</p> */}
             </b>
             </div>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4 col-12">
             <h6 className="text-center font-weight-bold">
             {match.status_str}

             </h6>
             <p className="text-center">{match.date_start}</p>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4 col-12">
             <img alt="team logo"   style={{width:"60px"}} className="img-fluid float-right align-items-center w-20"  src={teamb.logo_url} />
             
             <div className='text-right '><b>
             <span className="mr-2">{teamb.name}</span>
             {/* <p className="mr-2 ">{teamb.scores_full}</p> */}
             </b>
             </div>
            
          </div> 
       </div>  

    
       </div></div>

                        
 
                         
                      <div className="position-relative mb-50-r">
                        <div className="  bg-white" >
                           
                           <div className="tab-content">
                              <div id="livescoring" className="tab-pane active">
                                 <br/> 
                                 <table className='table'>
                                    <tr>
                                       <td>Date : <br></br> {match.date_start}</td>
                                       <td>Venue:  <br></br>{venue.name}, {venue.location}, {venue.country}</td>
                                       <td>Toss:  <br></br>{toss.winner} <br></br> {toss.text} </td>
                                    </tr>
                                    <tr>
                                       <td>Umpires :  <br></br>{match.umpires} </td>
                                       <td>Tv Umpires :   <br></br>{match.equation}</td>
                                       <td>Match Referee:  <br></br>{match.referee}</td>
                                    </tr>
                                 </table>
                                 

                                  
                              </div>
                             
                           </div>

                           <ScheduleSquad></ScheduleSquad> 


                         



                        </div>
                     </div> 
                   
                  
                
                        </div>
                     </div>
                     </div>
                     <div id="sidebar" className="sidebar col-md-4">
                      <SideBar></SideBar>
                     </div>
                     </div>
                  </div>
         </section>
            
    </div>
  )
}
