import React, { useEffect, useState, Text } from "react";
import { useLocation } from "react-router-dom";
import axios from 'axios'

export default function MatchDetail() {
   var location = useLocation();
 //console.log(location.state.batsmen);
  //const currentinning = location.state.innings[(location.state.innings.length-1)]
 // console.log(location.state.innings.length);
  const [news, setNews] = useState(location.state)
  //const [currentinningbatsmen, setCurrentinningbatsmen] = useState(currentinning.current_partnership.batsmen)
  

//   var url_string = window.location.href;
//   var url = new URL(url_string);
//   var idss = url.searchParams.get("id");
//   const getAnswer = async () => {
//      axios.get("https://rest.entitysport.com/v2/matches/"+idss+"/scorecard?token=97e19253b3ceb33edc4ec694576c840b")
//     .then((response) => {
//    //  console.log(response.data.response);
//       setNews(response.data.response) 
//     // console.log(response);
//     })
//    };
//     useEffect(() => {
//      // getAnswer();
//     });

  return ( 
    <div>
      

      <section className="bg-body section-space-less30 bg-light" id={news.match_id}>
            <div className="container">
               <div className="row">
                  <div className="container">
                     <div className="card bg-white mb-20 p-20">
                        <div className="card-body">
                        <h6 className="text-center font-weight-bold "> {news.status_note}
                           </h6>
                          
                       <div className="row">
                              <div className="col-lg-4 col-sm-4 col-md-4 col-12">
   <img style={{width:"60px"}} className="img-fluid float-left align-items-center w-20 mr-3"  src={news.teams[0].logo_url}  alt="team logo"  />
   <br></br>
                                 <span className=" float-left align-items-center">{news.teams[0].title}</span>
                              </div>
                              <div className="col-lg-4 col-sm-4 col-md-4 col-12">
                                 <h6 className="text-center font-weight-bold">{news.status_str}</h6>
                                 <p className="text-center">{news.live_inning.scores_full}</p>
                              </div>
                              <div className="col-lg-4 col-sm-4 col-md-4 col-12">
                                 <img alt="team logo"   style={{width:"60px"}} className="img-fluid float-right align-items-center w-20"  src={news.teams[1].logo_url} />
                                 <br></br>  <span className="float-right align-items-center  mr-3">{news.teams[1].title}</span>
                              </div> 
                        </div>    
                           <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                 <table className="table table__cell-center table-thead-color ">
                                    <thead>
                                       <tr >
                                          <th>
                                             BATSMAN
                                          </th>
                                          <th>
                                             R
                                          </th>
                                          <th>
                                             B 
                                          </th>
                                          <th>
                                             4S
                                          </th>
                                          <th>
                                             6S
                                          </th>
                                          <th>
                                             SR
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr >
                                          <th>{news.batsmen[0].name}</th>
                                          <th>{news.batsmen[0].runs}</th>
                                          <th>{news.batsmen[0].balls_faced}</th>
                                          <th>{news.batsmen[0].fours}</th>
                                          <th>{news.batsmen[0].sixes}</th>
                                          <th>{news.batsmen[0].strike_rate}</th>
                                         
                                       </tr>
                                       <tr >
                                          <th>{news.batsmen[1].name}</th>
                                          <th>{news.batsmen[1].runs}</th>
                                          <th>{news.batsmen[1].balls_faced}</th>
                                          <th>{news.batsmen[1].fours}</th>
                                          <th>{news.batsmen[1].sixes}</th>
                                          <th>{news.batsmen[1].strike_rate}</th>
                                         
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                 <table className="table table__cell-center table-thead-color ">
                                    <thead>
                                       <tr >
                                          <th>Bowler</th>
                                          <th>O</th>
                                          
                                          <th>R</th>
                                          <th>M</th>
                                          <th>W</th>
                                          <th>ECON</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr >
                                           <th>{news.bowlers[0].name}</th>
                                          <th>{news.bowlers[0].overs}</th>
                                          <th>{news.bowlers[0].runs_conceded}</th>
                                          <th>{news.bowlers[0].maidens}</th>
                                          <th>{news.bowlers[0].wickets}</th>
                                          <th>{news.bowlers[0].econ}</th>
                                       </tr>
                                       <tr >
                                          <th>{news.bowlers[1].name}</th>
                                          <th>{news.bowlers[1].overs}</th>
                                          <th>{news.bowlers[1].runs_conceded}</th>
                                          <th>{news.bowlers[1].maidens}</th>
                                          <th>{news.bowlers[1].wickets}</th>
                                          <th>{news.bowlers[1].econ}</th>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                     <div className="position-relative mb-50-r">
                        <div className="container bg-white" >
                           <ul className="nav nav-tabs" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" data-toggle="tab" href="#home">Commentry</a>
                              </li>
                              {/* <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#menu1">Scored</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" data-toggle="tab" href="#menu2">Info</a>
                              </li> */}
                           </ul>
                           <div className="tab-content">
                              <div id="home" className="container tab-pane active">
                                 <br/> 
                                 <h6>CURRENT PARTNERSHIP:</h6>
                                 <p>{news.live_inning.last_wicket.score_at_dismissal}, {news.live_inning.last_wicket.name},  {news.live_inning.last_wicket.how_out}</p>
                                 <h6>CURRENT RUN RATE:</h6>
                                 <p>{news.live_score.runrate} | Last Ten Overs: {news.live_inning.last_five_overs}</p>
                                 <h6>RECENT SCORE</h6>
                                 <p>{news.live_inning.recent_scores}</p>
                                 {
            
            news.commentaries.reverse().map((value,index) => {
              
              
              return (
                                 <div className="commentry">
                                {value.event=='overend' &&
                                 <table className="table mb-20 mt-20 " >
                                    <thead>
                                       <tr >
                                          <th colSpan="2" className="border border-info-10"><strong
                                             className="float-left"> END OF OVER : {value.over} ({value.runs}) </strong>
                                             <strong className="float-right">{news.live_inning.name} | {value.score}</strong>
                                          </th>
                                       </tr>
                                    </thead>
                                    {/* <tbody>
                                       <tr >
                                          <td><span className="float-left">  {value.bats[0].batsman_id}  </span>
                                             <span className="float-right">13</span>
                                          </td>
                                          <td><span className="float-left">{value.bowls[0].bowler_id}</span>
                                             <span className="float-right">2-0-31-2</span>
                                          </td>
                                       </tr>
                                       <tr >
                                          <td><span className="float-left">{value.bats[1].batsman_id}</span>
                                             <span className="float-right">{value.bowls[1].bowler_id}</span>
                                          </td>
                                          <td><span className="float-left">Alick Athanaze</span>
                                             <span className="float-right">2-0-9-1</span>
                                          </td>
                                       </tr>
                                    </tbody> */}
                                 </table>
               }     
                               {value.event!='overend' &&
                                
                                 <ul className="mt-10 d-flex pl-2" style={{ listStyleType: "none" }}>
                                 {(function() {
                                           if (value.score =='w') {
                                             return  <li className="circle red"  ><span >{value.score}</span></li>;
                                           }  if (value.score == 4) {
                                             return <li className="circle blue"  ><span >{value.score}</span></li>;
                                           }  if (value.score == 6) {
                                             return <li className="circle green"  ><span >{value.score}</span></li>;
                                           }  
                                                      
                                           else {
                                             return <li className="circle no_color"  ><span >{value.score}</span></li>;
                                           }
                                         })()}
                                         
                                  
                                                                  {/* <li className="circle"  ><span >{value.score}</span></li> */}
                                 
                                                                     <li  >{value.over}.{value.ball} : {value.commentary}
                                                                     </li>
                                                                  </ul>
            }
                                 </div>
  );
})
} 

                                  
                              </div>
                              <div id="menu1" className="container tab-pane fade">
                                <br/>
                                 <table className="table  "
                                    >
                                    <thead>
                                       <tr >
                                          <th colSpan="7" className="border border-left-20 border-warning"><strong
                                             className="float-left"> FORT CHARLOTTE STRIKERS INNINGS</strong>
                                             <strong className="float-right">89/8 (10 Ov)</strong>
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr >
                                          <td colSpan="2"><strong> BATSMAN</strong></td>
                                          <td><strong>R</strong></td>
                                          <td><strong>B</strong></td>
                                          <td><strong>4S</strong></td>
                                          <td><strong>6S</strong></td>
                                          <td><strong>SR</strong></td>
                                       </tr>
                                       <tr >
                                       </tr>
                                       <tr >
                                          <td>Miles Bascombe </td>
                                          <td>c Leshawn Lewis b D Greaves </td>
                                          <td>16 </td>
                                          <td>7 </td>
                                          <td>2 </td>
                                          <td>1 </td>
                                          <td>228.57</td>
                                       </tr>
                                       <tr className="p-10">
                                          <td colSpan="7"><strong>Fall of Wickets:</strong><span> 0-1 (Casmus
                                             Hackshaw, 0.5),</span> <span>16-2 (Miles Bascombe,
                                             1.5),</span> <span>5-3 (Sealroy Williams, 2.5),</span>
                                             <span>1-4 (Donwell Hector, 4.3), </span><span>0-5 (Richie
                                             Richards, 4.5),</span> <span>32-6 (Joshua James, 7.4),
                                             </span><span>14-7 (Preston McSween, 8.6),</span> <span>0-8 (Ray
                                             Jordan, 9.2)</span>
                                          </td>
                                          
                                       </tr>
                                       <tr className="p-10">
                                          <td colSpan="7"><strong>Yet To Bat:</strong> <span>Javid Harry,</span> </td>
                                       </tr>
                                      
                                    </tbody>
                                 </table>
                                 <table className="table  mt-20 mb-20"
                                    >
                                    <thead>
                                       <tr >
                                          <th>BOWLER</th>
                                          <th>O	</th>
                                          <th>M	</th>
                                          <th>R	</th>
                                          <th>W	</th>
                                          <th>NB	</th>
                                          <th>WD	</th>
                                          <th>ECON
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td>Darius Martin	</td>
                                          <td>2	</td>
                                          <td>0	</td>
                                          <td>7	</td>
                                          <td>2	</td>
                                          <td>0	</td>
                                          <td>2	</td>
                                          <td>3.50</td>
                                       </tr>
                                    </tbody>
                                 </table>
                                 <table className="table">
                                    <thead>
                                       <tr >
                                          <th colSpan="7" className="border-left-20 border-warning"><strong
                                             className="float-left"> FORT CHARLOTTE STRIKERS INNINGS</strong>
                                             <strong className="float-right">89/8 (10 Ov)</strong>
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr >
                                          <td colSpan="2"><strong> BATSMAN</strong></td>
                                          <td><strong>R</strong></td>
                                          <td><strong>B</strong></td>
                                          <td><strong>4S</strong></td>
                                          <td><strong>6S</strong></td>
                                          <td><strong>SR</strong></td>
                                       </tr>
                                       <tr >
                                          <td>Miles Bascombe </td>
                                          <td>c Leshawn Lewis b D Greaves </td>
                                          <td>16 </td>
                                          <td>7 </td>
                                          <td>2 </td>
                                          <td>1 </td>
                                          <td>228.57</td>
                                       </tr>
                                       <tr className="p-10">
                                          <td colSpan="7"><strong>Fall of Wickets:</strong><span> 0-1 (Casmus
                                             Hackshaw, 0.5),</span> <span>16-2 (Miles Bascombe,
                                             1.5),</span> <span>5-3 (Sealroy Williams, 2.5),</span>
                                             <span>1-4 (Donwell Hector, 4.3), </span><span>0-5 (Richie
                                             Richards, 4.5),</span> <span>32-6 (Joshua James, 7.4),
                                             </span><span>14-7 (Preston McSween, 8.6),</span> <span>0-8 (Ray
                                             Jordan, 9.2) </span>
                                          </td>
                                         
                                       </tr>
                                       <tr >
                                          <td colSpan="7"><strong>Yet To Bat:</strong> <span>Javid Harry,</span> </td>
                                       </tr>
                                    </tbody>
                                 </table>
                                 <table className="table mt-20 mb-20 "
                                    >
                                    <thead>
                                       <tr >
                                          <th>BOWLER</th>	
                                          <th>O	</th>
                                          <th>M	</th>
                                          <th>R	</th>
                                          <th>W	</th>
                                          <th>NB	</th>
                                          <th>WD	</th>
                                          <th>ECON
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td>Darius Martin	</td>
                                          <td>2	</td>
                                          <td>0	</td>
                                          <td>7	</td>
                                          <td>2	</td>
                                          <td>0	</td>
                                          <td>2	</td>
                                          <td>3.50</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              <div id="menu2" className="container tab-pane fade">
                                 <br/>
                                 <table className="table mt-20 mb-20">
                                    <thead>
                                       <tr >
                                          <th colSpan="2" className="border-left-20 border-warning"><strong
                                             > Match Details </strong>
                                          </th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr >
                                          <td colSpan="2"></td>
                                       </tr>
                                       <tr >
                                          <td>Date : 21/03/2022 12:00
                                          </td>
                                          <td>
                                             Venue : Arnos Vale Ground, Kingstown, St Vincent2-0-31-2
                                          </td>
                                       </tr>
                                       <tr >
                                          <td>
                                             Toss : Fort Charlotte Strikers won the toss and elected to field
                                          </td>
                                          <td>
                                             Umpires :
                                          </td>
                                       </tr>
                                       <tr>
                                          <td colSpan="2">Match Referee :</td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                   
                  </div>
                
               </div>
            </div>
         </section>
         
    </div>
  )
}
