import React, { useEffect, useState } from 'react'
 

export default function HomeRankTable() {

  const [t20s, setData] = useState([])
  useEffect(() => {
    fetch(`https://rest.entitysport.com/v2/iccranks?token=ec471071441bb2ac538a0ff901abd249`).then((result) => {
      result.json().then((resp) => {
        setData(resp.response.ranks.teams.t20s);

      })
    })

  }, [])

  return (
    <>
    <aside className="widget card widget--sidebar widget-standings">
      <div className="widget__title card__header card__header--has-btn">
        <h4>ICC T20 Ranking</h4>
        {/* <a href="#" className="btn btn-default btn-outline btn-xs card-header__button">See All Stats</a> */}
      </div>
      <div className="widget__content card__content">
        <div className="table-responsive">
          <table className="table table-hover table-standings">
            <thead>
              <tr>
              <th>Rank </th>
              <th>Team</th>
              <th>Rating</th>
              <th>Points</th>
             
              </tr>
            </thead>
            <tbody>
            {

t20s.slice(0, 10).map((value, index) => {

  return (

  <tr>
    <td>{value.rank}</td>
    <td>
      <div className="team-meta">
        <figure className="team-meta__logo "><img src={value.logo_url} alt="" /></figure>
        <div className="team-meta__info">
          <h6 className="team-meta__name ml-2">{value.team}
            </h6><span className="team-meta__place"></span>
        </div>
      </div>
    </td>
    
      {/* <td>{value.team}</td> */}
      <td>{value.rating}</td>

      <td>{value.points}</td>
     
   
  </tr>
  );
})
}
                
                </tbody>
          </table>
        </div>
      </div>
    </aside>   
    
    </>
  )
}
