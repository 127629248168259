import React, { useEffect, useState, Text } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import HomeTabsNews from "./HomeTabsNews";
import SideBar from "./SideBar";

export default function Blog() {

  const [blog,setData] = useState([])
  useEffect(() => {
    fetch(`https://admin.cricplex.com/mobileapi/blog_list`).then((result)=>{
      result.json().then((resp)=>{
         // console.log(resp.news)
        setData(resp.blog);
      
      })
    })

  },[])
        
  
    return (
    <>
         <div class="page-heading">   
   <div class="container">
      <div class="row">
         <div class="col-md-10 offset-md-1">
            <h1 class="page-heading__title">Latest <span class="highlight"> Blogs</span></h1>
            <ol class="page-heading__breadcrumb breadcrumb">
               <li class="breadcrumb-item"><a href="/">Home</a></li>
               <li class="breadcrumb-item active" aria-current="page">Blogs</li>
            </ol>
         </div>
      </div>
   </div>
</div>
       <div class="site-content">
				<div class="container">
				<div class="row">
				<div class="content col-md-8">
                   
				<div class="card card--clean">
				<div class="posts posts--cards post-grid row">
             
                {
                blog.map((value,index) => {
                return (

				<div class="post-grid__item col-sm-6">
	            <div   class="posts__item posts__item--card posts__item--category-1 card" title="title">
				<figure class="posts__thumb">
				 
                <a href={"/blog-detail/"+ value.id} >
                    <img src={value.image} alt="" />
                </a>
				 </figure>
				 <div class="posts__inner card__content">
                 <a href={"/blog-detail/"+ value.id} class="posts__cta"></a>
				<h3 class="posts__title"><a href="#">{value.heading} </a></h3>
                <time datetime={value.createdate} class="posts__date">{value.createdate}, {value.createdby} </time>

					</div>
					</div>
					</div> 

                    );
                    })
                    } 

					</div>
					 
					</div>
					</div> 
                    <div id="sidebar" class="sidebar col-md-4">
			 
					
					 
					  
                        <SideBar></SideBar>  
						</div>

					</div> 
					</div> 
					</div>
       
    </>
  )
}

