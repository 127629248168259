 import React, { useEffect, useState, Text } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'


import { Link } from 'react-router-dom'
import HomeFootballScore from '../Football/HomeFootballScore'
import LiveMatchesHome from './LiveMatchesHome';
import AllHomeScore from './AllHomeScore';
import Header_Tab from '../Matches/Header_Component/Header_Tab';
import Header_Live_Matches from '../Matches/Header_Component/Header_Live_Matches';
import Header_All_Matches from '../Matches/Header_Component/Header_All_Matches';
import BreakingNews from "./BreakingNews";
import ApiConfig from "../ApiConfig";
export default function Header() {

  const [video_category, SetData] = useState([])
  const [Season, setSeason] = useState([])
  const apikey = ApiConfig.cricket_api_key;
  const api_url = ApiConfig.api_url;
  const getAnswer = async () => {
    await axios.get("https://admin.cricplex.com/Mobileapi/video_category")
     .then((response) => {
   //  console.log(response.data.vedio_category);
       SetData(response.data.vedio_category)
       
     })
 };


 const getSeason = async () => {
  axios.get("https://rest.entitysport.com/v2/seasons/?token=&token=bf9e12cc4c8f3fbb4c457c9062b84492")
  .then((response) => {
  //  console.log(response);
  setSeason(response.data.response.items)
  })
};

 useEffect(() => {
            getAnswer();
            getSeason();
         },[]);
 
    // var today = new Date();
    // var dd = String(today.getDate()).padStart(2, '0');
    // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    // var yyyy = today.getFullYear();
    
    // today = dd + '/' + mm + '/' + yyyy;
     
  return (
    <>
 
    <div className="site-wrapper clearfix">
        
		<div className="site-overlay"></div>

		<div className="marquee-wrapper">
			<div className="container">
		
				<div className="marquee-label">
					Breaking News
				</div>
     

			<BreakingNews></BreakingNews>
	
			</div>
		</div>
    <div className='container-fluid   '>
    <div className="header-mobile clearfix" id="header-mobile">
			<div className="header-mobile__logo">
				<Link to="/">
		<img src={"/assets/images/cricplex.png"}   alt="Cricplex Radio" className="header-mobile__logo-img" /></Link>
        
			</div>
			<div className="header-mobile__inner">
				<a id="header-mobile__toggle" data-toggle="collapse" data-target="#collapsibleNavbar" className="burger-menu-icon"><span className="burger-menu-icon__line"></span></a>
				<span className="header-mobile__search-icon" id="header-mobile__search-icon"><a href="/live-radio" className="" title="Live Radio">
			<img  src={"/assets/images/radio-icon.gif"} width="100"  />
							</a></span>
			</div>
		</div>

        <nav class="navbar navbar-expand-md bg-dark navbar-dark d-sm-none pt-0 pb-0">
  
   
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav">
       
      <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
        Matches
      </a>
      <div class="dropdown-menu">
        <Link class="dropdown-item" to="/livematches">Live Matches</Link>
        <Link class="dropdown-item" to="/schedule-matches">Schedule Matches</Link>
        <Link class="dropdown-item" to="/recent-matches">Recent Matches</Link>

          
      </div>
    </li>  

    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
        News
      </a>
      <div class="dropdown-menu">
      <a class="dropdown-item" href="/latest-news">Latest News</a>
      <a class="dropdown-item" href="/category-news/22">Paralympics </a>
      <a class="dropdown-item" href="/category-news/22">Men's IPL 2024 </a>
       </div>
    </li>

    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
      Ranking
      </a>
      <div class="dropdown-menu">
      <Link class="dropdown-item" to="/icc-batsmen-ranking">Batsmen</Link>
      <Link class="dropdown-item" to="/icc-bowler-ranking">Bowlers</Link>
      {/* <Link class="dropdown-item" to="/">All Rounder</Link> */}
      <Link class="dropdown-item" to="/icc-teams-ranking">Teams</Link>
      </div>
    </li>

    
    <li className=""><Link to="/series/live" > Series </Link>  </li>


 
    <li className=""><Link to="/Paralympics" > Paralympics </Link>  </li>
    <li><Link class="" href="/live-radio">Live Radio</Link></li>
    <li>    <div id="google_translate_element"></div></li>


    </ul>
  </div>  
</nav>
        <header className="header header--layout-1">
		 
        <div className="header__primary">
            <div className="container-fluid">
                <div className="header__primary-inner">
                   
                    <div className="header-logo">
                        <Link to="/"><img src={"/assets/images/cricplex.png"} alt="Cricplex Sports Radio "  className="header-logo__img" /></Link>
                    </div>
                    

                    
                    <nav className="main-nav clearfix text-center"  >
                        <ul className="main-nav__list">
                                <li className=""><Link to="/" > Home </Link>  </li>
                                <li className=""><a href="#" aria-disabled="true" className="disabled"> Matches </a>
                                <ul className="main-nav__sub">
                                    <li className=""><Link to="/livematches"> Live Matches</Link></li>
                                    <li className=""><Link to="/schedule-matches"> Schedule Matches</Link></li>
                                    <li className=""><Link to="/recent-matches"> Recent Matches</Link></li>
                                  </ul>
                                 </li>
                            
                                 <li className=""><a href="#" aria-disabled="true" className="disabled"> News </a>
                                <ul className="main-nav__sub">
                                    <li className="">    <Link  class="dropdown-item" to="/latest-news"> Latest News</Link></li>
                                    <li className="">   <Link class="dropdown-item" to="/category-news/22">Paralympics </Link></li>
                                      <li className="">   <Link class="dropdown-item" to="/category-news/20">Men's IPL 2024</Link></li>
                                   
                                 </ul>
                                 </li>

                                 <li className=""><a href="#" aria-disabled="true" className="disabled"> Videos </a>
                                <ul className="main-nav__sub">
                                {
                        video_category.map((value,index) => {
                          var id= value.id;
                          return (
                                  <li className=""><a href={"/video-category/"+id}> {value.title}</a></li>
                                );
                              })
                            } 
                               
                                  </ul>
                                 </li>
                                 <li className=""><Link to="/series/live" > Series </Link>  </li>
 

                                 <li className=""><a href="#" aria-disabled="true" className="disabled"> Ranking </a>
                                <ul className="main-nav__sub">
                                <li className=""><Link to="/icc-teams-ranking"> Teams</Link></li>
                                <li className="">  <Link to="/icc-batsmen-ranking">Batsmen</Link></li>
                                <li className=""><Link to="/icc-bowler-ranking"> Bowlers</Link></li>
                                
                                   
                                    
 
                                  </ul>
                                 </li>

                                 {/* <li className=""><a href="#">Teams</a>
                                <ul className="main-nav__sub">
                                    <li className=""><a href="/latest-news">Teams Facts</a></li>
                                    <li className=""><a href="/ipl-2022-news">Teams </a></li>
                                </ul>
                            </li> */}

                            <li className=""><Link to="/Paralympics" > Paralympics </Link>  </li>
                            <li className=""><Link to="/live-radio" > Live Radio </Link>  </li>
                          
                            
                           
                            
                                 
                            
                          
                     
                            

                               
                        </ul>
                           
                      
                        {/* <a href="/live-radio" className="" title="Live Radio">
                            <img src={"/assets/images/radio-icon.gif"} width="100"/>
                        </a> */}

                        
                    </nav>
                   
                </div>
            </div>
        </div>
    
    
    </header>
  
  
      {/* <AllHomeScore></AllHomeScore> */}
      {/* <Header_Live_Matches></Header_Live_Matches> */}
    
      {/* <Header_All_Matches></Header_All_Matches> */}
    </div>

    

        </div>


        
    </>
  )
}

