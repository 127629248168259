import React, {useEffect, Fragment, useState} from 'react';
import {Link} from "react-router-dom";
export default function Paralympic_News() {

    const [news,setData] = useState([])
    useEffect(() => {
      fetch(`https://admin.cricplex.com/mobileapi/category_wise_news/22`).then((result)=>{
        result.json().then((resp)=>{
           // console.log(resp.news)
        //  setData(resp.news.slice(0, 5));
          setData(resp.news.slice(0, 10));
        
        })
      })
  
    },[])

  return (
         <div className="card card--clean">
    <header className="card__header card__header--has-btn">
      <h4>Paralympic News</h4><a href="#" className="btn btn-default btn-outline btn-xs card-header__button">See All Posts</a>
    </header>
    <div className="card__content">{/* Posts List */}
      <div className="posts posts--cards posts--cards-thumb-left post-list">
      
      {
                news.map((value,index) => {
                return (
        <div className="post-list__item">
          <div className="posts__item posts__item--card posts__item--category-1 card card--block">
            <figure className="posts__thumb"> <Link to={'news-detail/'+value.id} >
            <img src={value.image} alt={value.category} />
                </Link>
            
            </figure>
            <div className="posts__inner">
              <div className="card__content">
                <div className="posts__cat"><span className="label posts__cat-label"> 
                {value.cat_name}</span></div>
                <h6 className="posts__title"><a href="#">{value.heading}</a></h6><time dateTime="2016-08-17" className="posts__date">{value.createdate}</time>
                {/* <div className="posts__excerpt">{value.summery}</div> */}
              </div>
              <footer className="posts__footer card__footer">
                <div className="post-author">
                   <div className="post-author__info">
                    <h4 className="post-author__name">{value.createdby}</h4>
                  </div>
                </div>
                <ul className="post__meta meta">
               
                  <li className="meta__item meta__item--comments"><a href="#">Read More</a>
                  </li>
                </ul>
              </footer>
            </div>
          </div>
        </div>


);
})
} 
       
      </div>{/* Posts List / End */}
    </div>
  </div>
   )
}
