import React, { useEffect, useState, Text } from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
 export default function HomeVideosShort() {


  const [vedio_list, SetData] = useState([])
  const [id, SetDataid] = useState([])
  const [title, SetDatatitle] = useState([])
  const [category_id, SetDatacategory_id] = useState([])
  const [catname, SetDatacatname] = useState([])
  const [image, SetDataimage] = useState([])
  const [createdate, SetDatacreatedate] = useState([])
  const [url, SetDataurl] = useState([])
  const [image_url, SetDataimage_url] = useState([])

  const getAnswer = async () => {
    await axios.get("https://admin.cricplex.com/Mobileapi/short_video_list")
     .then((response) => {
   //  console.log(response.data.vedio_category);
       SetData(response.data.vedio_list)
       SetDataid(response.data.vedio_list[0].id)
       SetDatatitle(response.data.vedio_list[0].title)
       SetDatacategory_id(response.data.vedio_list[0].category_id)
       SetDatacatname(response.data.vedio_list[0].catname)
       SetDataimage(response.data.vedio_list[0].image)
       SetDatacreatedate(response.data.vedio_list[0].createdate)
       SetDataurl(response.data.vedio_list[0].url)
       SetDataimage_url(response.data.vedio_list[0].image_url)
     })
 };

  


 useEffect(() => {
            getAnswer();
         },[]);
  
  return (
    <>
 
                 
       
      
      <div className='row'>
      
      {vedio_list.slice(0, 4).map((value,index) => {
                          var id= value.id;
                          return (       
    <div class="posts__item posts__item--card posts__item--category-3 col-sm-3"><div class="vc_column-inner"><div class="wpb_wrapper">
<Link to={'watch-video/'+value.id} >
 <div class="card sponsor-card">
 {/* <iframe          width="100%"
                  height="200"
                  src={value.url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe> */}
            
             <figure class="posts__thumb">
                                     <img src={value.image_url} alt="Sponsor Image Placeholder"/> 

                             </figure>
    
                              
     <div class="card__content sponsor-card__content p-1 video_text_box">
                     <div class="sponsor-card__excerpt">
                 <p>{value.title}</p>
             </div>

             
                  
     </div>
      
 </div>
 </Link>
 
 </div></div>
  
 
 </div>
 
);
})
}
    </div>

 


    

    </>
  );
}
