import React from 'react'

export default function HomeLink() {
  return (
    <>
    <div className='container-fluid'>
    <div id="tag_cloud-2" class="widget   widget_tag_cloud">
       
        <div class="tagcloud"> 








<a href="/latest-news" class="tag-cloud-link tag-link-19 btn btn-primary btn-xs btn-outline btn-sm  tag-link-position-1" aria-label="Alchemists (9 items)">Latest News</a>
<a href="/video-category/10" class="tag-cloud-link tag-link-19 btn btn-primary btn-xs btn-outline btn-sm  tag-link-position-1" aria-label="Alchemists (9 items)">Factomania Videos</a>
<a href="category-news/22" class="tag-cloud-link tag-link-20 btn btn-primary btn-xs btn-outline btn-sm  tag-link-position-2" aria-label="awards (2 items)">Paralympics News</a>
<a href="/icc-teams-ranking" class="tag-cloud-link tag-link-22 btn btn-primary btn-xs btn-outline btn-sm  tag-link-position-4" aria-label="Coach (2 items)">ICC Teams Ranking</a>
<a href="/icc-batsmen-ranking" class="tag-cloud-link tag-link-22 btn btn-primary btn-xs btn-outline btn-sm  tag-link-position-4" aria-label="Coach (2 items)">ICC Batsmen Ranking</a>
<a href="/icc-bowler-ranking" class="tag-cloud-link tag-link-22 btn btn-primary btn-xs btn-outline btn-sm  tag-link-position-4" aria-label="Coach (2 items)">ICC Bowler Ranking</a>
<a href="/recent-matches" class="tag-cloud-link tag-link-25 btn btn-primary btn-xs btn-outline btn-sm  tag-link-position-7" aria-label="news (1 item)">Recent Match</a>
 
 
</div>
</div>
</div>
    </>
  )
}
