import React, { useEffect, useState, Text } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import HomeTeamStats from "../Matches/HomeTeamStats";

export default function SideBar() {
  const [news, setNews] = useState([])
   
   

      const getAnswer = async () => {
       await axios.get("https://admin.cricplex.com/example/news")
        .then((response) => {
       // console.log(response);
          setNews(response.data.data)
          
        })
    };
    useEffect(() => {
               getAnswer();
            });

            const navigate = useNavigate();
            const handleClick = (data) => {
              axios.get("https://admin.cricplex.com/example/avrnewsdetail/"+data.id)
                .then((response) => {
                      navigate('/news-detail',{
                          state: response.data.data
               });
                   
                  })
             
            }
            
  
    return (
    <>
    
				 
    <aside className="widget widget--sidebar card widget-popular-posts">
    <div className="widget__title card__header">
                           <h4>Advertisement</h4>
                        </div>
                   <div className="widget__content card__content">
                        <img src={"/assets/images/ads/2.jpg"} alt="500 Fx"  width="100%" />
                        </div>
                   </aside>
			 
                    <aside className="widget widget--sidebar card widget-popular-posts">
                  
                        <div className="widget__title card__header">
                           <h4>Popular News</h4>
                        </div>

                        <div className="widget__content card__content">
                           <ul className="posts posts--simple-list">

                           {
                news.slice(5, 10).map((value,index) => {
                return (
                              <li    className="posts__item posts__item--category-2">
                                
                                 <figure className="posts__thumb"><a href={"/news-detail/"+ value.id}><img src={"https://admin.cricplex.com/news_images/"+ value.image} style={{width:"100px"}} alt=""/></a></figure>
                                 <div className="posts__inner">
                                    {/* <div className="posts__cat"><span className="label posts__cat-label">{value.category}</span></div> */}
                                    <h6 className="posts__title"><a href={"/news-detail/"+ value.id}>{value.heading}</a></h6>
                                    <time datetime="2016-08-23" className="posts__date">{value.createdate}</time>
                                 </div>
                               
                              </li>
                              
                              );
                            })
                            } 
                           </ul>
                        </div>
                      
                          
                       
                     </aside>  

                     <aside className="widget widget--sidebar card widget-popular-posts">
                     {/* <div className="widget__title card__header">
                           <h4>Latest Updates</h4>
                        </div>
                        <div className="widget__content card__content">
                           <ul className="posts posts--simple-list">

                           {
                news.slice(0, 5).map((value,index) => {
                return (
                              <li onClick={()=>handleClick(value)}  className="posts__item posts__item--category-2">
                                 <figure className="posts__thumb"><a href="#"><img src={"https://admin.cricplex.com/news_images/"+ value.image} style={{width:"100px"}} alt=""/></a></figure>
                                 <div className="posts__inner">
                                    <div className="posts__cat"><span className="label posts__cat-label">{value.category}</span></div>
                                    <h6 className="posts__title"><a href="#">{value.heading}</a></h6>
                                    <time datetime="2016-08-23" className="posts__date">{value.createdate}</time>
                                 </div>
                              </li>
                              );
                            })
                            } 
                           </ul>
                        </div> */}
                        <div className="widget__content card__content">
                        <img src={"/assets/images/500fxads.jpg"} alt="500 Fx"  width="100%" />
                        </div>
                        </aside>

                     {/* <HomeTeamStats></HomeTeamStats> */}
					 
 
       
    </>
  )
}

