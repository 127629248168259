import React, { Component, useEffect, useState, Text } from 'react'
import { BrowserRouter as Router, useParams} from "react-router-dom";

import axios from 'axios'

export default function ScoreTopSection() {

    const { id } = useParams();

  const [match, SetMatch] = useState([]);
  const [competition, Setcompetition] = useState([]);
  const [teama,setTeama] = useState([]);
  const [teamb,setTeamb] = useState([]);
   
const [items, setItems] = useState([{}]);
// console.log(batsmen1);
useEffect(() => {
    axios.get('https://rest.entitysport.com/v2/matches/'+id+'/info?token=bf9e12cc4c8f3fbb4c457c9062b84492').then(response => {
      SetMatch(response.data.response);
      Setcompetition(response.data.response.competition);
      
      setTeama(response.data.response.teama);
      setTeamb(response.data.response.teamb);
      
 
      
    }).catch(err => console.log(err));
}, [])


  return (
    <div>   <div className='score_top_section'>
    <h6 className="text-center font-weight-bold "> {match.subtitle}, {match.format_str},  {competition.title}
       </h6>
      
     <div className="row">
          <div className="col-lg-4 col-sm-4 col-md-4 col-12">
<img style={{width:"60px"}} className="img-fluid float-left align-items-center w-20 mr-3"  src={teama.logo_url}  alt="team logo"  />
              <div className='float-left '><b>
             <span className="">{teama.name}</span>
             <p className=" ">{teama.scores_full}</p>
             </b>
             </div>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4 col-12">
             <h6 className="text-center font-weight-bold">
             {match.status_str =='Live' ? (
        <><b className='text-danger blink_me'>{match.status_str}</b></>
      ) : (
      <>{match.status_str}</>
      )}

             </h6>
             <p className="text-center">{match.status_note}




             </p>
          </div>
          <div className="col-lg-4 col-sm-4 col-md-4 col-12">
             <img alt="team logo"   style={{width:"60px"}} className="img-fluid float-right align-items-center w-20"  src={teamb.logo_url} />
             
             <div className='text-right '><b>
             <span className="mr-2">{teamb.name}</span>
             <p className="mr-2 ">{teamb.scores_full}</p>
             </b>
             </div>
            
          </div> 
       </div>  

    
       </div></div>
  )
}
