import React from 'react'

export default function Footer() {
  return (
    <>

<div class="footer-widgets">
				<div class="footer-widgets__inner"  >
					<div class="container">
						<div class="row">
							<div class="col-sm-12 col-md-3">
								<div class="footer-col-inner">
		
									 
									<div class="footer-logo">
										<a href="/"><img src={"/assets/images/cricplex.png"}     alt="Cricplex Sports Radio" class="footer-logo__img"/></a>
									</div>
								 
		
								</div>
							</div>
							<div class="col-sm-3 col-md-2">
								<div class="footer-col-inner">
									<div class="widget widget--footer widget-contact-info">
										<h4 class="widget__title">Contact Info</h4>
										<div class="widget__content">
											<div class="widget-contact-info__body info-block">
												<div class="info-block__item">
													
													<h6 class="info-block__heading">Contact Us</h6>
													<a class="info-block__link" href="mailto:business@cricplex.com">business@cricplex.com</a>
												</div>
												<div class="info-block__item">
													
													<h6 class="info-block__heading">Join Our Team!</h6>
													<a class="info-block__link" href="mailto:hr@aannya.com">hr@aannya.com</a>
												</div>
												<div class="info-block__item info-block__item--nopadding">
													<ul class="social-links">
		
														<li class="social-links__item">
															<a href="https://www.facebook.com/CricPlex/" title="facebook page" target="_blank" class="social-links__link"><span class="fa-stack">
            <i class="fas fa-circle fa-stack-2x"  ></i>
            <i class="fab fa-facebook-f fa-stack-1x"  ></i>
          </span> </a>
														</li>
														<li class="social-links__item">
															<a href="https://twitter.com/CricPlex" title="twitter page"  target="_blank" class="social-links__link"><span class="fa-stack">
            <i class="fas fa-circle fa-stack-2x"  ></i>
            <i class="fab fa-twitter fa-stack-1x"  ></i>
          </span></a>
														</li>
														<li class="social-links__item">
															<a href="https://www.youtube.com/channel/UCXSkYJY2Q7AhJEmeoNQwFHA" title="youtube channel" target="_blank" class="social-links__link"> <span class="fa-stack">
            <i class="fas fa-circle fa-stack-2x"  ></i>
            <i class="fab fa-youtube fa-stack-1x"  ></i>
          </span></a>
														</li>
														
														<li class="social-links__item">
															<a href="https://www.instagram.com/cricplex/" title="Instagram" target="_blank" class="social-links__link"> <span class="fa-stack">
            <i class="fas fa-circle fa-stack-2x"  ></i>
            <i class="fab fa-instagram fa-stack-1x"  ></i>
          </span></a>
														</li>
		
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-3 col-md-2">
								<div class="footer-col-inner">
									
									<div class="widget widget--footer widget-popular-posts">
										<h4 class="widget__title">Important Links</h4>
										<div class="widget__content">
											<ul class="posts posts--simple-list">
	 						 
											
	 						
											
 
	
<li class="posts__item posts__item--category-2">
	<h6 class="posts__title posts__title--color-hover"><a href="/live-radio" >Live Radio</a></h6>
</li>
 
 
<li class="posts__item posts__item--category-2">
	<h6 class="posts__title posts__title--color-hover"><a href="/privacy-policy" >Privacy Policy</a></h6>
</li>

<li class="posts__item posts__item--category-2">
	<h6 class="posts__title posts__title--color-hover"><a href="/terms-and-conditions" >Terms & Conditions</a></h6>
</li>
 

												
												
		
											</ul>
										</div>
									</div>
									
								</div>
							</div>
							<div class="col-sm-3 col-md-2">
								<div class="footer-col-inner">
									<div class="widget widget--footer widget-popular-posts">
										<h4 class="widget__title"> Download Links</h4>
										<div class="widget__content">
											<ul class="posts posts--simple-list">
		
												{/* <li class="posts__item posts__item--category-2">
													
													<h6 class="posts__title posts__title--color-hover"><a href="https://itunes.apple.com/bt/app/cricplex-live-cricket-jockey/id1437522092" target="_blank"> iOS Download</a></h6>
													
												</li> */}
												<li class="posts__item posts__item--category-2">
													
													<h6 class="posts__title posts__title--color-hover"><a href="https://play.google.com/store/apps/details?id=com.aannya.cricplex" target="_blank"> Android Download</a></h6>
													
												</li>
												
		
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-3 col-md-3">
								<div class="footer-col-inner">
									<div class="widget widget--footer widget-popular-posts">
										<h4 class="widget__title"> Matches</h4>
										<div class="widget__content">
											<ul class="posts posts--simple-list">
								
											 
											 

												<li class="posts__item posts__item--category-2">
													<h6 class="posts__title posts__title--color-hover">
														<a href="/livematches" > Live Matches</a>
														</h6> 
													<h6 class="posts__title posts__title--color-hover">
														<a href="/schedule-matches" > Schedule Matches</a>
														</h6> 
													<h6 class="posts__title posts__title--color-hover">
														<a href="/recent-matches" > Recent Matches</a>
														</h6> 
												</li>
												
		
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
       	<div class="footer-secondary footer-secondary--has-decor">
				<div class="container">
					<div class="footer-secondary__inner">
						<div class="row">
							<div class="col-md-10 offset-md-1">
								<ul class="footer-nav">
		
									<li class="footer-nav__item"><a href="https://www.aannya.com" target="_blank">© 2022 Aannya Software Pvt Ltd. All Rights Reserved </a></li>
																	
		
								</ul>
								
							</div>
						</div>
					</div>
				</div>
			</div>
    </>
  )
}
