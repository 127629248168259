import React, {useEffect, Fragment, useState} from 'react';
import {Link} from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
export default function Top_News() {

    const [news,setData] = useState([])
    useEffect(() => {
      fetch(`https://admin.cricplex.com/mobileapi/top10news`).then((result)=>{
        result.json().then((resp)=>{
           // console.log(resp.news)
        //  setData(resp.news.slice(0, 5));
          setData(resp.news.slice(0, 10));
        
        })
      })
  
    },[])

  return (
      
      <> 
 

          <Carousel
  swipeable={true}
  draggable={true}
 
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
 
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
 
  
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
{
                news.map((value,index) => {
                return (

				<div class="post-grid__item m-2 top_slider_news ">
	            <div class="posts__item posts__item--card posts__item--category-1 card" title="title">
				<figure class="posts__thumb">
				<div class="posts__cat">
				 <span class="label posts__cat-label">{value.cat_name}</span>
				</div>
                <Link to={'news-detail/'+value.id} >
                    <img src={value.image} alt={value.category} style={{height:"180px"}} />
                </Link>
				 </figure>
				 <div class="posts__inner card__content">
                 <a href={'news-detail/'+value.id} class="posts__cta"></a>
				 <time  class="posts__date">{value.createdate}</time>
				<h3 class="posts__title"><a href={'news-detail/'+value.id}> 
        
        {value.heading.length > 75 ?
    `${value.heading.substring(0, 75)}...` : value.heading
  }
        
        </a></h3>
				 
					</div>
					</div>
					</div> 

                    );
                    })
                    } 
</Carousel> 
        </>
      
   )
}
