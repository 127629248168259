import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function AjmanCup() {
    const [news, setNews] = useState([])
     
      const getAnswer = async () => {
        axios.get("https://admin.cricplex.com/example/ajmancup")
        .then((response) => {
        //  console.log(response);
          setNews(response.data.data)
        })
    };

    useEffect(() => {
               getAnswer();
            });
       
            const navigate = useNavigate();
            const handleClick = (data) => {
              axios.get("https://admin.cricplex.com/example/avrnewsdetail/"+data.id)
                .then((response) => {
                      navigate('/news-detail',{
                          state: response.data.data
               });
                   
                  })
             
            }

  return (
    <div>
         <div className="sidebar-box">
                                    <div className="topic-border color-cod-gray mb-30">
                                        <div className="topic-box-lg color-cod-gray">AJMAN CUP</div>
                                    </div>
                                    <div className="add-item5-lg">
                                 {
            
            news.map((value,index) => {
              
              return (
                                       <div className="media bg-body item-shadow-gray mb30-list">
                                            <a className="img-opacity-hover width40-lg" onClick={()=>handleClick(value)} >
                                                <img style={{width:"100px"}} src={"https://admin.cricplex.com/news_images/"+ value.image} alt="news" className="img-fluid"/>
                                            </a>
                                            <div className="media-body">
                                                <div className="post-date-dark">
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                <i className="fa fa-calendar" aria-hidden="true"></i>
                                                            </span>{value.createdate}</li>
                                                    </ul>
                                                </div>
                                                <h3 className="title-medium-dark mb-none">
                                                <a onClick={()=>handleClick(value)} >{value.heading}</a>
                                                </h3>
                                            </div>
                                        </div>
                                      );
                                    })
                                  } 
                                    </div>
                                </div>
    </div>
  )
}
